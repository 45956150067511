import { RoutePath } from 'routes/route-path.constant';

export const LocalStorageKeys = {
    [RoutePath.MainPage]: 'mainPage',
    [RoutePath.BodyGender]: 'gender',
    [RoutePath.EthnicBackground]: 'ethnicBackground',
    [RoutePath.LoseWeight]: 'loseWeight',
    [RoutePath.FamilyWeight]: 'familyWeight',
    [RoutePath.PastAttempts]: 'pastAttempts',
    [RoutePath.ExerciseHours]: 'exerciseHours',
    [RoutePath.LoseWeightReason]: 'loseWeightReason',
    [RoutePath.PrescriptionMedications]: 'prescriptionMedications',
    [RoutePath.BodyIdealWeight]: 'weightGoal',
    [RoutePath.HealthCondition]: 'healthCondition',
    [RoutePath.HealthConditionPart2]: 'healthConditionPart2',
    [RoutePath.MedicalCondition]: 'medicalCondition',
    [RoutePath.MedicalConditionPart2]: 'medicalConditionPart2',
    [RoutePath.MedicationIntake]: 'medicationIntake',
    [RoutePath.MedicationIntakePart2]: 'medicationIntakePart2',
    [RoutePath.BloodPressureInformation]: 'bloodPressureInformation',
    [RoutePath.GeneralPractitionerInformation]: 'generalPractitionerInformation',
    [RoutePath.Consent]: 'consent',
    [RoutePath.Email]: 'email',
};

export const MultipleLocalStorageKeys = {
    bodyHeightWeightKeys: {
        unitSystem: 'body-height-weight-unitSystem',
        heightCm: 'body-height-ft',
        weightKg: 'body-weight-lbs',
        bmi: 'body-height-weight-bmi'
    },
    [RoutePath.Checkout]: {
        firstName: 'customer-first-name',
        lastName: 'customer-last-name',
        password: 'customer-password',
        dateOfBirth: 'customer-date-of-birth',
        phone: 'customer-phone',
        streetAddress: 'customer-street-address',
        address2: 'customer-address-2',
        city: 'customer-city',
        county: 'customer-county',
        postCode: 'customer-post-code',
        fullAddress: 'customer-full-address',
        googleValidatorFormattedAddress: 'customer-google-validator-formatted-address',
    }
};
