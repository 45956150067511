import React from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export const MedicalDirectorCard = () => {
    const { medicalDirectorCardWrapper} = useStyles();

    const pageImages = useBackgroundImageService(['webp/medication/medical-director.webp']);

    return (
        <div className={medicalDirectorCardWrapper}>
            <img src={pageImages[0]} alt='Medical Director' />
        </div>
    );
};
