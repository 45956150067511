import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isEmpty } from 'helpers';

import { UK_KEY } from 'hooks/use-user-geolocation.hook';

import { GoogleAddressValidation } from 'api/google-address-validation.api';

import { NextButton } from 'components/next-button';
import { GoogleStreetAddress, ShippingAddress } from 'components/shipping-address';
import { CorrectionModalWindow } from 'components/shipping-address/correction-modal-window';
import { validateResponse } from 'components/shipping-address/validation';

import { useStyles } from './styles';

export const ShippingAddressEmbedded: FC = () => {
    window.document.body.style.background = '#F6F6F6';

    const { pageWrapper, pageWrapperDark, addButton, addButtonDark } = useStyles();

    const [validationListener, setValidationListener] = useState(false);

    const [addressCorrected, setAddressCorrected] = useState(false);

    const [streetAddressInvalid, setStreetAddressInvalid] = useState<null | boolean>(null);
    const [address2Invalid, setAddress2Invalid] = useState<null | boolean>(null);
    const [cityInvalid, setCityInvalid] = useState<null | boolean>(null);
    const [countyInvalid, setCountyInvalid] = useState<null | boolean>(null);
    const [postCodeInvalid, setPostCodeInvalid] = useState<null | boolean>(null);

    const [searchParams] = useSearchParams();
    const addressParam = searchParams.get('address');
    const nightThemeParam = searchParams.get('nightTheme');
    const nightTheme = nightThemeParam === 'true';
    const fullInitialAddress = addressParam ? JSON.parse(addressParam) : {};
    const {
        address2: initialAddress2,
        city: initialCity,
        state: initialCounty,
        streetAddress: initialStreetAddress,
        zipCode: initialPostCode,
        fullAddress: initialFullAddress,
    } = fullInitialAddress;

    const [streetAddress, setStreetAddress] = useState(initialStreetAddress || '');
    const [address2, setAddress2] = useState(initialAddress2 || '');
    const [city, setCity] = useState(initialCity || '');
    const [county, setCounty] = useState(initialCounty || '');
    const [postCode, setPostCode] = useState(initialPostCode || '');
    const [fullAddress, setFullAddress] = useState(initialFullAddress || '');
    const [googleValidatorFormattedAddress, setGoogleValidatorFormattedAddress] = useState(initialFullAddress || '');

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const addressValid = !(isEmpty(streetAddress) || isEmpty(city) || isEmpty(county) || isEmpty(postCode));

        if (addressValid) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [streetAddress, city, county, postCode]);

    const onAddress2Change = (e: any) => {
        setAddress2(e.target.value);
        setAddress2Invalid(false);
    };

    const onCityChange = (e: any) => {
        setCity(e.target.value);
        setCityInvalid(false);
    };

    const onCountyChange = (e: any) => {
        setCounty(e.target.value);
        setCountyInvalid(false);
    };

    const onPostCodeChange = (e: any) => {
        setPostCode(e.target.value);
        setPostCodeInvalid(false);
    };

    const toggleValidation = () => {
        setValidationListener(!validationListener);
    };

    const handleSelectStreetAddress = ({ result, name }: GoogleStreetAddress) => {

        if (validationListener && !result?.address_components?.length) {
            return;
        }

        setStreetAddress(name);

        setFullAddress(result.formatted_address);

        setStreetAddressInvalid(false);
        setAddress2Invalid(false);
        setCityInvalid(false);
        setCountyInvalid(false);
        setPostCodeInvalid(false);

        // street_number               -> address street number
        // route                       -> the street name
        // postal_town                 -> the city/town
        // administrative_area_level_3 -> the city/town
        // administrative_area_level_2 -> the county
        // postal_code                 -> zip/postal code
        result.address_components.forEach((address) => {
            if (address.types.includes('postal_town') || address.types.includes('administrative_area_level_3')) {
                setCity(address.long_name);
            }
            if (address.types.includes('administrative_area_level_2')) {
                setCounty(address.short_name);
            }
            if (address.types.includes('postal_code')) {
                setPostCode(address.long_name);
            }
        });

        toggleValidation();
    };

    const handleComponentSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const emptyStreetAddress = isEmpty(streetAddress);
        const emptyCity = isEmpty(city);
        const emptyCounty = isEmpty(county);
        const emptyPostCode = isEmpty(postCode);

        if (emptyStreetAddress || emptyCity || emptyCounty || emptyPostCode) {
            if (emptyStreetAddress) {
                setStreetAddressInvalid(true);
            }
            if (emptyCity) {
                setCityInvalid(true);
            }
            if (emptyCounty) {
                setCountyInvalid(true);
            }
            if (emptyPostCode) {
                setPostCodeInvalid(true);
            }

            toggleValidation();
            return;
        }

        const addressLines = `${streetAddress}, ${address2} ${city}, ${county}, ${postCode}`;

        const response = await GoogleAddressValidation.validate({ addressLines, regionCode: UK_KEY });

        const validated = validateResponse(response);

        console.log('----- AddressLines', addressLines);
        console.log('----- AddressValidation Response', response);
        console.log('----- Validated Response', validated);

        setGoogleValidatorFormattedAddress(response.address.formattedAddress);

        setStreetAddressInvalid(!validated.streetAddress.valid);
        if (validated.streetAddress.changed) {
            setStreetAddress(validated.streetAddress.text);
        } else if (!isEmpty(validated.streetAddress.value) && streetAddress !== validated.streetAddress.value) {
            setStreetAddress(validated.streetAddress.value);
            validated.streetAddress.changed = true;
        }

        setAddress2Invalid(!validated.address2.valid);
        if (validated.address2.changed) {
            setAddress2(validated.address2.text);
        } else if (!isEmpty(validated.address2.value) && address2 !== validated.address2.value) {
            setAddress2(validated.address2.value);
            validated.address2.changed = true;
        }

        setCityInvalid(!validated.city.valid);
        if (validated.city.changed) {
            setCity(validated.city.text);
        } else if (!isEmpty(validated.city.value) && city !== validated.city.value) {
            setCity(validated.city.value);
            validated.city.changed = true;
        }

        setCountyInvalid(!validated.county.valid);
        if (validated.county.changed) {
            setCounty(validated.county.text);
        } else if (!isEmpty(validated.county.value) && county !== validated.county.value) {
            setCounty(validated.county.value);
            validated.county.changed = true;
        }

        setPostCodeInvalid(!validated.postCode.valid);
        if (validated.postCode.changed) {
            setPostCode(validated.postCode.text);
        } else if (!isEmpty(validated.postCode.value) && postCode.toString() !== validated.postCode.value) {
            setPostCode(validated.postCode.value);
            validated.postCode.changed = true;
        }

        // @ts-ignore
        if (Object.keys(validated).map((k: string) => validated[k]).some(c => !c.valid)) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        // @ts-ignore
        if (Object.keys(validated).map(k => validated[k]).some(c => c.changed)) {
            setAddressCorrected(true);
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const postMessage = JSON.stringify({
            streetAddress, address2, city, state: county, postCode, fullAddress,
            googleFormattedAddress: response.address.formattedAddress
        });

        window.webkit?.messageHandlers?.setAddress?.postMessage(postMessage); // iOS handler
        window.Mobile?.setAddress(postMessage); // Android injection
        window.parent.postMessage(postMessage, '*'); // PWA
    };


    return (
        <div className={`${pageWrapper} ${nightTheme ? pageWrapperDark : ''}`}>

            {addressCorrected &&
                <CorrectionModalWindow
                    address={googleValidatorFormattedAddress}
                    handleClick={() => setAddressCorrected(false)} />
            }

            <ShippingAddress streetAddress={streetAddress}
                             handleStreetAddressChange={(address) => { setStreetAddress(address) }}
                             handleSelectStreetAddress={handleSelectStreetAddress}
                             streetAddressInvalid={streetAddressInvalid}
                             address2={address2}
                             onAddress2Change={onAddress2Change}
                             address2Invalid={address2Invalid}
                             city={city}
                             onCityChange={onCityChange}
                             cityInvalid={cityInvalid}
                             county={county}
                             onCountyChange={onCountyChange}
                             countyInvalid={countyInvalid}
                             postCode={postCode}
                             onPostCodeChange={onPostCodeChange}
                             postCodeInvalid={postCodeInvalid}
                             validationListener={validationListener}
                             nightTheme={nightTheme}
            />

            <NextButton onClick={handleComponentSubmit} className={`${addButton} ${nightTheme ? addButtonDark : ''}`} typographyText={'Add'} disabled={disabled} />
        </div>
    );
};
