import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    agreementText: {
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '18px',
        margin: '32px 0 0',

        '& a': {
            color: '#1375D6'
        },

        '@media (min-width: 768px)' : {
            margin: '40px 0 0'
        }
    }
});
