import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    medicationDescriptionWrapper: {
        margin: '32px 0',
    },
    title: {
        fontSize: '24px',
        lineHeight: '34px',
        textAlign: 'left',
        margin: '0 0 32px 0',
    },
    medicationImage: {
        width: '100%',
        height: '200px',
        marginTop: '24px',
        borderRadius: '8px',
        objectFit: 'cover'
    },
    medicationDescription: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '24px auto 20px'
    },
    checkPointsListWrapper: {
        display: 'flex',
        gap: '12px',
        alignItems: 'flex-start',
        marginBottom: '16px',
    },
    checkPointText: {},
    divider: {
        border: '1px solid #C5C5D1',
        margin: '32px auto',
    },
    firstBlock: {},
    secondBlock: {},
    secondPointsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    secondPointWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '16px',

        '&:nth-of-type(5)': {
            marginBottom: '32px',
        }
    },
    thirdPointsWrapper: {
        margin: '20px auto 33px'
    },
    listWithCoachMessageWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    phoneImage: {
        position: 'relative',
        right: '-10%',
        width: '100px',
        rotate: '-15deg'
    },
    '@media (min-width: 768px)': {
        medicationDescriptionWrapper: {
            margin: '80px 0',
        },
        title: {
            fontSize: '34px',
            lineHeight: '44px',
            margin: '0 0 40px'
        },
        divider: {
            margin: '56px auto',
        },
        medicationDescription: {
            fontSize: '20px',
            lineHeight: '30px',
            margin: '32px auto 24px'
        },
        checkPointsListWrapper: {
            marginBottom: '20px',

            '&:last-of-type': {
                marginBottom: 0
            },

            '& img': {
                width: '32px',
                height: '32px'
            }
        },
        checkPointText: {
            fontSize: '20px',
            lineHeight: '30px'
        },
        firstBlock: {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
            gap: '32px'
        },
        secondBlock: {
            display: 'flex',
            gap: '32px',
            alignItems: 'flex-start',
        },
        medicationImage: {
            width: '278px',
            height: '217px',
            margin: '-32px 0 0',
            alignSelf: 'center'
        },
        secondPointWrapper: {
            marginBottom: '20px',

            '& p': {
                lineHeight: '24px'
            },

            '&:last-of-type': {
                marginBottom: 0,
            }
        },
        thirdPointsWrapper: {
            margin: '32px auto 33px'
        },
        phoneImage: {
            width: '278px',
            position: 'initial',
            background: '#FFEEDE',
            padding: '10px 77px',
            rotate: 'initial',
            borderRadius: '8px'
        }
    }
});
