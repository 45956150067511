import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    mainContent: {
        maxWidth: '560px',
        margin: '0 auto',
        position: 'relative',
        padding: ({ scrollableContent }: any) => scrollableContent ? '0px 16px 20px' : '0px 16px 40px',

        '@media only screen and (min-width: 768px)' : {
            padding: ({ scrollableContent }: any) => scrollableContent ? '0px 0px 20px' : '0px',
        }
    }
});
