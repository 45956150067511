import React from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { CheckPointsList } from 'components/check-points-list';

import { Title } from './title';
import { MedicalDirectorCard } from './medical-director-card';

import { useStyles } from './styles';

export const MedicationDescription = () => {
    const { t } = useTranslation();
    const isDesktop = useDesktopMediaWatcher();

    const {
        medicationDescriptionWrapper,
        title,
        medicationImage,
        medicationDescription,
        checkPointsListWrapper,
        checkPointText,
        divider,
        firstBlock,
        secondBlock,
        secondPointsWrapper,
        secondPointWrapper,
        thirdPointsWrapper,
        listWithCoachMessageWrapper,
        phoneImage
    } = useStyles();

    const pageImages = useBackgroundImageService([
        'webp/medication/medication-description/medication.webp',
        'svg/medication/medication-description/book.svg',
        'svg/medication/medication-description/try.svg',
        'svg/medication/medication-description/person.svg',
        'svg/medication/medication-description/clock.svg',
        'svg/medication/coach-message.webp',
    ]);

    const block1 = [
        t('medication-medication-description-block-1-option-1'),
        t('medication-medication-description-block-1-option-2'),
        t('medication-medication-description-block-1-option-3'),
    ];

    const block3 = [
        t('medication-medication-description-block-3-option-1'),
        t('medication-medication-description-block-3-option-2'),
    ];

    return (
        <div className={medicationDescriptionWrapper}>

            <h1 className={title}>
                {t('medication-medication-description-title')}
            </h1>

            <div>
                <Title number={1} text={t('medication-medication-description-block-1-title')} />

                <div className={firstBlock}>
                    <img src={pageImages[0]} className={medicationImage} alt="Medication" />

                    <div>
                        <p className={medicationDescription}>
                            {t('medication-medication-description-block-1-text')}
                        </p>

                        <CheckPointsList items={block1} listItemClassName={checkPointsListWrapper} listItemTextClassName={checkPointText} />
                    </div>
                </div>
            </div>

            <div className={divider}></div>

            <div>
                <Title number={2} text={t('medication-medication-description-block-2-title')} />

                <div className={secondBlock}>
                    <div className={secondPointsWrapper}>
                        <p className={medicationDescription}>
                            {t('medication-medication-description-block-2-text')}
                        </p>

                        {!isDesktop && <MedicalDirectorCard />}

                        <div className={secondPointWrapper}>
                            <img src={pageImages[1]} alt='Book' />
                            <p>
                                {t('medication-medication-description-block-2-option-1')}
                            </p>
                        </div>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[2]} alt='Try' />
                            <p>
                                {t('medication-medication-description-block-2-option-2')}
                            </p>
                        </div>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[3]} alt='Person' />
                            <p>
                                {t('medication-medication-description-block-2-option-3')}
                            </p>
                        </div>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[4]} alt='Clock' />
                            <p>
                                {t('medication-medication-description-block-2-option-4')}
                            </p>
                        </div>
                    </div>

                    {isDesktop && <MedicalDirectorCard />}
                </div>
            </div>

            <div className={divider}></div>

            <div className={listWithCoachMessageWrapper}>
                <div>
                    <Title number={3} text={t('medication-medication-description-block-3-title')} />
                    <div className={thirdPointsWrapper}>
                        <CheckPointsList items={block3} listItemClassName={checkPointsListWrapper} listItemTextClassName={checkPointText} />
                    </div>
                </div>

                <img src={pageImages[5]} className={phoneImage} alt='Coach Message' />
            </div>
        </div>
    );
};
