import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';
import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

import { ABPagesConfiguration } from 'services/ab-pages-configuration.service';

const getNextRoute = (routePath: string) => {
    const routesList = ABPagesConfiguration();

    return routesList[routePath].route.next();
};

const getRoutePathBySearch = (searchParams: URLSearchParams) => {

    const isAllRequired = isAllRequiredExists(searchParams);
    const position = searchParams.get('position');

    if (position === 'afteremail' && isAllRequired) {
        const route = getNextRoute(getNextRoute(RoutePath.Email));
        return route;
    }

    return null;
};

// description: Need to check also searchParams since we need it at first run
const isAllRequiredExists = (searchParams: URLSearchParams): boolean => {
    const email =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.Email])) ||
        Boolean(searchParams.get('email'));

    const gender =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender])) ||
        Boolean(searchParams.get('gender'));

    const unit =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem)) ||
        Boolean(searchParams.get('bodyweightmetricsystem')) ||
        Boolean(searchParams.get('bodyheightmetricsystem'));

    const height =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm)) ||
        Boolean(searchParams.get('currentbodyheight'));

    const weight =
        Boolean(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg)) ||
        Boolean(searchParams.get('currentbodyweight'));

    const weightGoal =
        Boolean(localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight])) ||
        Boolean(searchParams.get('goalbodyweight')) ||
        Boolean(searchParams.get('targetweight'));

    return email && gender && unit && height && weight && weightGoal;
};

const buildUTMSearch = (searchParams: URLSearchParams): string => {
    const search = {
        utm_medium: searchParams.get('utm_medium') as string,
        utm_source: searchParams.get('utm_source') as string,
        utm_campaign: searchParams.get('utm_campaign') as string,
        utm_campaign_id: searchParams.get('utm_campaign_id') as string,
        utm_content: searchParams.get('utm_content') as string,
        utm_term: searchParams.get('utm_term') as string,
        utm_client_id: searchParams.get('utm_client_id') as string,
        utm_adgroup_id: searchParams.get('utm_adgroup_id') as string,
        utm_adgroup: searchParams.get('utm_adgroup') as string,
        utm_content_id: searchParams.get('utm_content_id') as string,
        gclid: searchParams.get('gclid') as string,
    };

    // @ts-ignore
    Object.keys(search).forEach(k => !search[k] && delete search[k]);

    if (Object.keys(search).length) {
        return `?${(new URLSearchParams(search)).toString()}`;
    }

    return '';
};

export const useUTMSearch = () => {
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search?.toLowerCase());

    return buildUTMSearch(searchParams);
};

export const useEnchargeSearch = () => {
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search?.toLowerCase());
    const routePath = getRoutePathBySearch(searchParams);

    const UTMSearch = buildUTMSearch(searchParams);

    const isRequiredKeysExists = (requiredKeys: string[]): boolean => {
        if (!requiredKeys || !requiredKeys.length) {
            return true;
        }

        return requiredKeys.every(key => {
            if (Array.isArray(key)) {
                return key.some(k => Boolean(localStorage.getItem(k)));
            }

            return Boolean(localStorage.getItem(key));
        });
    };

    useEffect(() => {

        //@description: Encharge - fill the storage and redirect user back to the onboarding
        if (search) {
            for (let param of searchParams) {
                const key = param[0];

                if (key === 'gender') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyGender], param[1]);
                } else if (key === 'bodyweightmetricsystem') {
                    const unitSystem = UNIT_SYSTEM.metric;

                    localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem, unitSystem);
                } else if (key === 'bodyheightmetricsystem') {
                    const unitSystem = UNIT_SYSTEM.metric;

                    localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem, unitSystem);
                } else if (key === 'currentbodyweight') {
                    localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, param[1]);
                } else if (key === 'currentbodyheight') {
                    localStorage.setItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, param[1]);
                } else if (key === 'goalbodyweight' || key === 'targetweight') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyIdealWeight], param[1]);
                } else if (key === 'email') {
                    localStorage.setItem(LocalStorageKeys[RoutePath.Email], param[1]);
                } else {
                    localStorage.setItem(param[0], param[1]);
                }
            }
        }

    }, [search]);

    return {
        routePath,
        UTMSearch,
        isRequiredKeysExists,
    };
};
