import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import check from './check.svg';

import { useStyles } from './styles';

interface VerifiedFormProps {
    title: string,
    text: string,
    handleEdit: () => void;
}

export const VerifiedForm: FC<VerifiedFormProps> = ({ title, text, handleEdit }) => {
    const { t } = useTranslation();

    const { accountFormDetailsWrapper, blockTitle, infoWrapper, info, button, link } = useStyles();

    return (
        <div className={accountFormDetailsWrapper}>

            <div className={infoWrapper}>
                <h1 className={blockTitle}>
                    {title}
                </h1>
                <img src={check} alt='Check mark' />
            </div>

            <div className={infoWrapper}>
                <p className={info}>
                    {text}
                </p>

                <button className={button} onClick={handleEdit}>
                    <p className={`${info} ${link}`}>
                        {t('checkout-verified-form-edit')}
                    </p>
                </button>
            </div>

        </div>
    );
};
