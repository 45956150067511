import { InputStyles } from './input';

export type DropdownType = {
    dropdownWrapper: any,
    dropdown: any,
    dropdownActiveClass: any,
    dropdownErrorClass: any,

    dropdownMenu: any,
    dropdownMenuItem: any,
    selectedItem: any,
    arrow: any,
    openedDropdown: any,
};

export const DropdownStyles: DropdownType = {
    dropdownWrapper: InputStyles.input,
    dropdown: InputStyles.input,
    dropdownActiveClass: InputStyles.inputActiveClass,
    dropdownErrorClass: InputStyles.inputErrorClass,

    dropdownMenu: {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        marginTop: '-1px',
        maxHeight: '186px',
        overflowY: 'auto',
        position: 'absolute',
        top: '100%',
        width: '100%',
        zIndex: 3,
    },
    dropdownMenuItem: {
        cursor: 'pointer',
        padding: '10px',
        color: '#010101',
        background: '#FAFAFA',
        fontFamily: 'SF Pro Text',
        borderBottom: '1px solid #C5C5D1',
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    selectedItem: {
        color: '#010101',
        width: '100%',
        border: 'none',
        fontSize: '1rem',
        marginTop: '20px',
        lineHeight: '1.5rem',
    },
    arrow: {
        border: 'solid #5E626B',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: '4px',
        position: 'absolute',
        right: '16px',
        transform: 'rotate(45deg)'
    },
    openedDropdown: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        '& $arrow': {
            transform: 'rotate(-135deg)',
            bottom: '16px'
        }
    }
};
