import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ header, backArrowButton }: ThemeProps) => ({
    header: {
        zIndex: 2,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: header.header.height,
        left: '0px',
        background: header.header.background,
        position: header.header.position,
    },
    backButtonWrapper: {
        ...backArrowButton.backButtonWrapper,
    },
    backButtonIconInnerWrapper: {
        ...backArrowButton.backButtonIconInnerWrapper,
    },
    backButton: {
        ...backArrowButton.backButton,
    },
}));
