import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    remark: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'left',
        color: '#232323',
        opacity: .5,
        margin: '32px 0 12px',

        '@media (min-width: 768px)' : {
            margin: '40px 0 16px'
        }
    }
});
