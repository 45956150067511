import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    programItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',

        '& h1': {
            fontSize: '18px',
            lineHeight: '22px',
        }
    },
    circle: {
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        background: '#1375D6',
        color: '#FFFFFF',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        justifyContent: 'center',
        flexShrink: 0,
    },
    '@media (min-width: 768px)': {
        programItem: {
            gap: '16px',

            '& h1': {
                fontSize: '24px',
                lineHeight: '34px',
            }
        },
        circle: {
            width: '40px',
            height: '40px',
            fontSize: '20px',
        }
    }
});
