import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, TextAreaInput } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

export const BloodPressureInformation = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.BloodPressureInformation];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = () => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('blood-pressure-title'), value: pageValue },
        });

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Heading title={t('blood-pressure-title')} />

            <TextAreaInput value={pageValue} onChange={(event) => setPageValue(event.target.value)} placeholder={t('blood-pressure-textarea-placeholder')} required />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.trim().length} />
            </FloatedButton>
        </>
    );
};
