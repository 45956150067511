import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ input }: ThemeProps) => ({
    inputWrapper: input.inputWrapper,
    inputAddressInput: input.inputWrapper, // @description: interesting issue with a Google Autocomplete - they also use name 'inputWrapper' for a class, and it's break the flow
    inputAddressInputDark: input.inputWrapperDark,
    input: input.input,
    inputCloseIconClass: {
        padding: '16px 16px 16px 0',
    },
    inputActiveClass: {
        ...input.inputActiveClass
    },
    inputDisabledClass: {
        cursor: 'not-allowed',
        opacity: .4
    },
    inputErrorClass: {
        ...input.inputErrorClass
    },
    inputLabelClass: {
        ...input.inputLabel
    },
    inputLabelActiveClass: {
        ...input.inputLabelActive
    },
    inputLabelDark: {
        ...input.inputLabelDark
    },
    inputLabelErrorClass: {
        ...input.inputLabelError
    },
    inputEndAdornmentClass: {
        ...input.inputEndAdornment,
    },
    inputWrapperDark: {
        ...input.inputWrapperDark
    },
    inputDark: {
        ...input.inputDark
    },
    inputActiveClassDark: {
        ...input.inputActiveClassDark
    },
    inputStartAdornment: {
        ...input.inputStartAdornment
    }
}));

