import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { UNIT_SYSTEM } from 'constants/body-height-weight';

import { NextButton } from 'components/next-button';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';

export const BodyHeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const [pageError, setPageError] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});


    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const { pageValue: bodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: UNIT_SYSTEM.metric
        });

    const { pageValue: bodyHeightCm } = useLocalStorage({
        key: bodyHeightWeightKeys.heightCm,
        defaultValue: '',
    });

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        const data = {
            unit: bodyHeightWeightUnitSystem,
            height: `${bodyHeightCm}`
        };

        saveAnswers({
            key: 'body-height',
            itemQuestion: { question: t('body-height-title'), value: `Height: ${data.height} cm` },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = pageError || !bodyHeightCm;

        setDisabledButtonState(btnState);

    }, [
        bodyHeightWeightUnitSystem,
        bodyHeightCm,
        pageError
    ]);

    return (
        <>
            <Heading title={t('body-height-title')} />

            <BodyHeightWeightInputs heightInput idealWeightInput={false} setInputError={setError} pageError={pageError} />

            <NextButton onClick={handleClick} disabled={disabledButtonState} />
        </>
    );
};
