import React from 'react';

import closeIcon from './close_icon.svg';

import { useStyles } from './styles';

type ModalWindowProps = {
    handleClose?: () => void;
    wrapperClassName?: string;
    modalWindowFormClassName?: string;
    hasCloseIcon?: boolean;
    children: any;
}

export const ModalWindow = ({ handleClose, wrapperClassName, modalWindowFormClassName, hasCloseIcon=true, children }: ModalWindowProps) => {
    const { modalWindowWrapper, modalWindowForm, close } = useStyles();

    const handleOnClose = () => {
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <div style={{height: '100%'}} className={`${modalWindowWrapper} ${wrapperClassName || ''}`}>
            <div className={`${modalWindowForm} ${modalWindowFormClassName || ''}`}>
                {handleClose && hasCloseIcon &&
                    <img
                        className={close}
                        src={closeIcon}
                        onClick={handleOnClose}
                        alt=""
                    />
                }
                {children}
            </div>
        </div>
    )
}
