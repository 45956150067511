import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { useStyles } from './styles';

export const MainPage = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { multiChoiceWrapper} = useStyles();

    const pageKey = LocalStorageKeys[RoutePath.MainPage];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'eighteen-years',
            label: t('main-page-option-eighteen-years')
        },
        {
            value: 'voluntarily',
            label: t('main-page-option-voluntarily')
        },
        {
            value: 'consult',
            label: t('main-page-option-consult')
        },
        {
            value: 'deposit-payment',
            label: t('main-page-option-deposit-payment')
        },
        {
            value: 'video-consultation',
            label: t('main-page-option-video-consultation')
        },
        {
            value: 'complete-details',
            label: t('main-page-option-complete-details')
        },
        {
            value: 'final-decision',
            label: t('main-page-option-final-decision')
        },
        {
            value: 'weight-loss-program',
            label: t('main-page-option-weight-loss-program')
        }
    ];

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.value === item);

            return currentOption?.label;
        });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('health-condition-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Heading title={t('main-page-title')} />

            <div className={multiChoiceWrapper}>
                <MultiChoice
                    options={multiChoices}
                    selectedOptions={pageValue}
                    onSelect={setPageValue}
                />
            </div>

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton typographyText={t('main-page-button-next')} onClick={handleClick} disabled={pageValue.length !== 8} />
            </FloatedButton>
        </>
    );
};
