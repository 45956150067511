import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserEligibilityKeys } from 'constants/user-eligibility';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getSelectedPageValues, isNextButtonDisabled } from 'helpers/multichoice-button-options';
import { isUserStatusNeedsReview } from 'helpers/get-user-eligibility';

export const MedicationIntakePart2 = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicationIntakePart2];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: prescriptionOnlyMedicinesUserAnswer, setPageValue: setPrescriptionOnlyMedicinesUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicationIntakePart2]}-prescription-only-medicines-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: opiatesUserAnswer, setPageValue: setOpiatesUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicationIntakePart2]}-opiates-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: cocaineUserAnswer, setPageValue: setCocaineUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicationIntakePart2]}-cocaine-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'prescription-only-medicines',
            label: t('medication-intake-part-2-option-prescription-only-medicines'),
            userAnswer: prescriptionOnlyMedicinesUserAnswer,
            setUserAnswer: setPrescriptionOnlyMedicinesUserAnswer,
            placeholder: t('medication-intake-part-2-textarea-placeholder-details'),
        },
        {
            value: 'opiates',
            label: t('medication-intake-part-2-option-opiates'),
            userAnswer: opiatesUserAnswer,
            setUserAnswer: setOpiatesUserAnswer,
            placeholder: t('medication-intake-part-2-textarea-placeholder-details'),
        },
        {
            value: 'cocaine',
            label: t('medication-intake-part-2-option-cocaine'),
            userAnswer: cocaineUserAnswer,
            setUserAnswer: setCocaineUserAnswer,
            placeholder: t('medication-intake-part-2-textarea-placeholder-details'),
        },
        {
            value: 'none',
            label: t('medication-intake-part-2-option-none'),
        }
    ];

    const selectedPageValues = getSelectedPageValues(pageValue);

    const handleClick = () => {
        const selectOption = selectedPageValues.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.value === item);

            return currentOption?.value === 'none' ? currentOption?.label : `${currentOption?.label}: ${currentOption?.userAnswer?.trim()}`;
        });

        let userStatus = selectedPageValues[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicationIntakePageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntake]) as string);
        const medicalConditionPageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalCondition]) as string);
        const medicalConditionPart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalConditionPart2]) as string);

        if (
            isUserStatusNeedsReview(medicationIntakePageValue, userStatus) ||
            isUserStatusNeedsReview(medicalConditionPageValue, userStatus) ||
            isUserStatusNeedsReview(medicalConditionPart2PageValue, userStatus)
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('medication-intake-part-2-title'), value: selectOption },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus }
        });

        pageConfiguration.handleOnPageComplete(!selectedPageValues.includes('none') ? {
            pageValue,
            selectOption
        } : { pageValue });
    };

    return (
        <>
            <Heading title={t('medication-intake-part-2-title')} subtitle={t('medication-intake-part-2-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick}
                            disabled={isNextButtonDisabled(selectedPageValues, multiChoices)} />
            </FloatedButton>
        </>
    );
};
