import { isEmpty } from 'helpers';

export type ValidationQueryType = {
    value: string | number,
    errorMessage?: string
}

export type ValidationTypeProps = {
    value: 'emptyString' | 'email' | 'number' | 'date' | 'phone' | 'pattern',
    pattern?: RegExp,
    format?: string,
    errorMessage?: string,
    lessThen?: ValidationQueryType,
    moreThen?: ValidationQueryType,
    equal?: ValidationQueryType
}

const typeValidator = {
    emptyString: (value: string) => isEmpty(value),
    email: (value: string) => value.length > 70 || !/^[\w%+-]+(\.[\w%+-]+)*@[\w%+-]+(\.[\w%+-]+)+$/.test(value),
    phone: (value: string, pattern: RegExp) => !pattern.test(value),
    pattern: (value: string, pattern: RegExp) => !pattern.test(value),
    // @TODO: Format? Check in future?
    date: (value: string, format: string) => {
        const isValidRegexp = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.test(value);
        if (!isValidRegexp) {
            return !isValidRegexp;
        }

        const today = new Date().getTime();
        const [dd, mm, yyyy] = value.split('/').map(p => parseInt(p));

        const userDate = new Date(yyyy, mm - 1, dd);
        const isValidDate = today - userDate.getTime() < 0;
        if (isValidDate) {
            return isValidDate;
        }

        return !(userDate.getMonth() === mm - 1 && userDate.getDate() === dd && userDate.getFullYear() === yyyy);
    },
}

export const validator = {
    maxLength: (value: number | string, options: ValidationQueryType) => value.toString().trim().length > options.value,
    minLength: (value: number | string, options: ValidationQueryType) => value.toString().trim().length < options.value,
    // @ts-ignore
    type: (value: string, { value: type, pattern }: ValidationTypeProps) => typeValidator[type](value, pattern)
}

export const numberValidator = {
    moreThen: (value: number, options: ValidationQueryType) => !(value > options.value),
    lessThen: (value: number, options: ValidationQueryType) => !(value < options.value),
    equal: (value: number, options: ValidationQueryType) => value !== options.value
}
