import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { TipBanner } from './tip-banner';
import { Program } from './program';
import { Rating } from './rating';
import { VideoCustomerReviews } from './video-customer-reviews';
import { MedicationDescription } from './medication-description';
import { ReviewsIO } from './reviews-io';
import { Expectations } from './expectations';
import { FAQ } from './faq';

import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const Medication = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const isDesktop = useDesktopMediaWatcher();

    const {
        tipPageWrapper,
        childrenWrapper,
        faqWrapper,
        buttonTitleWrapper,
        buttonTitle,
        nextButtonStyles,
    } = useStyles();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <>
            <TipBanner />

            <div className={tipPageWrapper}>
                <div className={childrenWrapper}>
                    <Program onClick={handleClick} />
                    <Rating />
                </div>
            </div>

            <div className={childrenWrapper}>
                <MedicationDescription />
            </div>

            <div>
                <VideoCustomerReviews />

                <ReviewsIO />

                <div className={childrenWrapper}>
                    <Expectations />

                    <div className={faqWrapper}>
                        <FAQ />
                    </div>
                </div>

                <div className={buttonTitleWrapper}>
                    {isDesktop && <h1 className={buttonTitle}>
                        {t('medication-program-tailored-program')}
                    </h1>}
                    <NextButton buttonWrapperClassName={nextButtonStyles} onClick={handleClick}
                                typographyText={t('medication-program-button-next')} />
                </div>
            </div>
        </>
    );
};
