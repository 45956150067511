import React, { FC } from 'react';

import { useStyles } from './styles';

export interface TitleProps {
    number: number,
    text: string,
}

export const Title: FC<TitleProps> = ({ number, text }) => {

    const {
        programItem,
        circle
    } = useStyles();

    return (
        <div className={programItem}>
            <div className={circle}>{number}</div>
            <h1>
                {text}
            </h1>
        </div>
    );
};
