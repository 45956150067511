import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, getSelectedPageValues } from 'helpers/multichoice-button-options';

export const FamilyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.FamilyWeight];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'mother',
            label: t('family-weight-option-mother')
        },
        {
            value: 'father',
            label: t('family-weight-option-father')
        },
        {
            value: 'sibling',
            label: t('family-weight-option-sibling')
        },
        {
            value: 'grandparent',
            label: t('family-weight-option-grandparent')
        },
        {
            value: 'none',
            label: t('family-weight-option-none'),
        },
    ];

    const handleClick = () => {
        const selectedPageValues = getSelectedPageValues(pageValue);
        const selectOption = getMultiChoiceButtonSelectOptions(selectedPageValues, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('family-weight-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue as IMultiChoiceButtonOption);
    };

    return (
        <>
            <Heading title={t('family-weight-title')} subtitle={t('family-weight-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
