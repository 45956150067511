import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const WeightFactors = () => {
    const { t } = useTranslation();

    const {
        title,
        description,
        weightFactorsIllustrationWrapper
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const pageImages = useBackgroundImageService(['svg/weight-factors/weight-factors-illustration.svg']);

    return (
        <>
            <h1 className={title}>
                <Trans i18nKey='weight-factors-title' />
            </h1>

            <p className={description}>
                <Trans i18nKey='weight-factors-description' />
            </p>

            <div className={weightFactorsIllustrationWrapper}>
                <img src={pageImages[0]} alt='Weight Factors Illustration' />
            </div>

            <NextButton typographyText={t('weight-factors-button-next')} onClick={handleClick} />
        </>
    );
};
