import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getUnitSystem, UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserEligibilityKeys } from 'constants/user-eligibility';

import { NextButton } from 'components/next-button';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';

import { useStyles } from './styles';

export const BodyIdealWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { remark } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.BodyIdealWeight];

    const { pageValue: bodyIdealWeight, setPageValue: setBodyIdealWeight } = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageValue: eligibleBMIValue } = useLocalStorage({
        key: UserEligibilityKeys.isBMIValueEligible,
        defaultValue: true
    });

    const { pageValue: bodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            defaultValue: UNIT_SYSTEM.metric
    });

    const unitSystem = getUnitSystem();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete({ bodyIdealWeight, unit: unitSystem, eligibleBMIValue });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-ideal-weight-title'), value: `Ideal weight: ${bodyIdealWeight} kg` },
        });
    };

    const [pageError, setPageError] = useState(false);
    const [userUnderweight, setUserUnderweight] = useState(false);

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = pageError || !bodyIdealWeight;

        setDisabledButtonState(btnState);

    }, [
        bodyHeightWeightUnitSystem,
        setBodyIdealWeight,
        pageError
    ]);

    return (
        <>
            <Heading title={t('body-ideal-weight-title')} />

            <BodyHeightWeightInputs heightInput={false} idealWeightInput setUserUnderweight={setUserUnderweight} userUnderweight={userUnderweight} setInputError={setError} pageError={pageError} />

            <p className={remark}>
                {t('body-ideal-weight-remark')}
            </p>

            <NextButton onClick={handleClick} disabled={disabledButtonState} />
        </>
    );
};
