import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton, button }: ThemeProps) => ({
    pageWrapper: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '24px 16px',

        '& > div': {
            width: '100%'
        }
    },
    pageWrapperDark: {
        background: '#1C1C1E'
    },
    addButton: {
        ...button.button,
        ...nextButton.nextButton,
        background: '#4B46EC',

        '&:active': {
            ...nextButton.nextButtonHovered,
            background: '#4B46EC',
        },

        '&:disabled': {
            ...nextButton.nextButtonDisabled,
        }
    },
    addButtonDark: {
        background: '#8E8BF3',

        '&:active': {
            background: '#8E8BF3',
        },

        '&:disabled': {
            background: '#757575',
            color: '#373739'
        }
    }
}));
