import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { CmInput } from 'components/body-height-weight-inputs/cm-input';
import { KgInput } from 'components/mass-input/kg-input';

import { getBmi } from 'services/body-plan-calculator.rx.service';

import { useStyles } from './styles';

type ErrorType = {
    error: boolean;
    key: string;
}

export interface BodyHeightWeightInputsProps {
    heightInput: boolean;
    idealWeightInput: boolean;
    setInputError: Dispatch<SetStateAction<ErrorType>>;
    userUnderweight?: boolean;
    setEligibleBMIValue?: (eligibleBMIValue: boolean) => void;
    setUserUnderweight?: (underweight: boolean) => void;
    pageError: boolean;
}

export const BodyHeightWeightInputs: FC<BodyHeightWeightInputsProps> = ({
                        heightInput,
                        idealWeightInput,
                        setInputError,
                        userUnderweight,
                        setEligibleBMIValue,
                        setUserUnderweight,
                        pageError
    }) => {

    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { marginTop, thanksForSharingTextWrapper, thanksForSharingText, excitementText } = useStyles();

    const { pageValue: bodyHeightCm, setPageValue: setBodyHeightCm } = useLocalStorage({
        key: MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
        defaultValue: '',
    });

    const { pageValue: bodyWeightKg, setPageValue: setBodyWeightKg } = useLocalStorage({
        key: MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
        defaultValue: '',
    });

    const { pageValue: bodyIdealWeight, setPageValue: setBodyIdealWeight } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    const minKgValue = 26.76;
    const maxKgValue = 454.046;
    const minCmValue = 60;
    const maxCmValue = 273;

    const onChangeCm = (e: any) => {
        setBodyHeightCm(e.target.value);
    };

    const onChangeKg = (e: any) => {
        idealWeightInput ? setBodyIdealWeight(e.target.value) : setBodyWeightKg(e.target.value);
    };

    const onError = (error: boolean, key: string) => {
        setInputError((prevState: ErrorType) => ({
            ...prevState,
            [key]: error,
        }));
    };

    const currentWeight = parseInt(bodyWeightKg);
    const idealWeight = parseInt(bodyIdealWeight);
    const weight = idealWeightInput ? idealWeight : currentWeight;
    const height = parseInt(bodyHeightCm);
    const bmi = getBmi(weight, height);

    const ethnicBackground = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.EthnicBackground]) as string);
    const ethnicBackgroundValues = ethnicBackground.map((val: any) => val.value);
    const isUserBackgroundNotWhite = ethnicBackgroundValues.includes('asian') || ethnicBackgroundValues.includes('middle-eastern') || ethnicBackgroundValues.includes('african');
    const minBMIValue = isUserBackgroundNotWhite ? 27.4 : ethnicBackgroundValues.includes('white') ? 29.9 : 20;

    useEffect(() => {

        const eligibleBMIValue =
            !!bodyWeightKg &&
            bodyWeightKg > minKgValue &&
            bodyWeightKg !== '' &&
            bodyWeightKg < maxKgValue &&
            bodyHeightCm > minCmValue &&
            bodyHeightCm !== '' &&
            bodyHeightCm < maxCmValue &&
            bmi > minBMIValue;

        const underweightValue =
            !!bodyIdealWeight &&
            bodyIdealWeight > minKgValue &&
            bodyIdealWeight !== '' &&
            bodyIdealWeight < maxKgValue &&
            bodyHeightCm > minCmValue &&
            bodyHeightCm !== '' &&
            bodyHeightCm < maxCmValue &&
            bmi < 18.5;

        if (setEligibleBMIValue) {
            setEligibleBMIValue(eligibleBMIValue);
        }

        if (setUserUnderweight) {
            setUserUnderweight(underweightValue);
        }

    }, [bodyWeightKg, bodyHeightCm, bodyIdealWeight, bmi]);

    useEffect(() => {
        saveAnswers({
            key: MultipleLocalStorageKeys.bodyHeightWeightKeys.bmi,
            itemQuestion: { question: t('medication-intake-form-user-bmi-question'), value: getBmi(currentWeight, height).toFixed(2) }
        });
    }, [currentWeight, height]);

    return (
        <>
            <>
                {heightInput && <CmInput value={bodyHeightCm} onChange={onChangeCm} onError={onError} />}

                {!heightInput && !idealWeightInput && <div className={marginTop}>
                    <KgInput value={bodyWeightKg} onChange={onChangeKg} onError={onError} />
                </div>}

                {!heightInput && idealWeightInput && <div className={marginTop}>
                    <KgInput value={bodyIdealWeight} lessThen={Number(bodyWeightKg)} onChange={onChangeKg}
                             onError={onError} />
                </div>}
            </>

            {!pageError && bodyWeightKg && !heightInput && !idealWeightInput && (
                <div className={thanksForSharingTextWrapper}>
                    <p className={thanksForSharingText}>
                        {t('body-height-weight-thanks-for-sharing-title')}
                    </p>
                    <p className={thanksForSharingText}>
                        {t('body-height-weight-thanks-for-sharing-description')}
                    </p>
                </div>
            )}

            {!pageError && bodyIdealWeight && !userUnderweight && idealWeightInput && (
                <>
                    <p className={excitementText}>
                        {t('body-ideal-weight-goal-excitement-title')}
                    </p>
                    <p className={excitementText}>
                        {t('body-ideal-weight-goal-excitement-description')}
                    </p>
                </>
            )}
        </>
    );
};
