import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UserEligibilityKeys } from 'constants/user-eligibility';
import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, getSelectedPageValues } from 'helpers/multichoice-button-options';
import { isUserStatusNeedsReview } from 'helpers/get-user-eligibility';

export const MedicalCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicalCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'high-blood-pressure',
            label: t('medical-condition-option-high-blood-pressure')
        },
        {
            value: 'high-cholesterol',
            label: t('medical-condition-option-high-cholesterol')
        },
        {
            value: 'gallbladder-disease',
            label: t('medical-condition-option-gallbladder-disease')
        },
        {
            value: 'malabsorption-syndrome',
            label: t('medical-condition-option-malabsorption-syndrome')
        },
        {
            value: 'none',
            label: t('medical-condition-option-none'),
        }
    ];

    const handleClick = () => {
        const selectedPageValues = getSelectedPageValues(pageValue);
        const selectOption = getMultiChoiceButtonSelectOptions(selectedPageValues, multiChoices);

        let userStatus = selectedPageValues[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicalConditionPart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalConditionPart2]) as string);
        const medicationIntakePageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntake]) as string);
        const medicationIntakePart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntakePart2]) as string);

        if (
            isUserStatusNeedsReview(medicalConditionPart2PageValue, userStatus) ||
            isUserStatusNeedsReview(medicationIntakePageValue, userStatus) ||
            isUserStatusNeedsReview(medicationIntakePart2PageValue, userStatus)
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('medical-condition-title'), value: selectOption },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus }
        });

        pageConfiguration.handleOnPageComplete(pageValue as IMultiChoiceButtonOption);
    };

    return (
        <>
            <Heading title={t('medical-condition-title')} subtitle={t('medical-condition-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
