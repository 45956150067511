import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, getSelectedPageValues } from 'helpers/multichoice-button-options';

export const PastAttempts = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PastAttempts];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'fitness-trackers',
            label: t('past-attempts-option-fitness-trackers')
        },
        {
            value: 'calorie-counting-apps',
            label: t('past-attempts-option-calorie-counting-apps')
        },
        {
            value: 'gym-exercise',
            label: t('past-attempts-option-gym-exercise')
        },
        {
            value: 'meal-planning',
            label: t('past-attempts-option-meal-planning')
        },
        {
            value: 'restrictive-dieting',
            label: t('past-attempts-option-restrictive-dieting')
        },
        {
            value: 'nutritionist-dietician',
            label: t('past-attempts-option-nutritionist-dietician')
        },
        {
            value: 'prescription-medication',
            label: t('past-attempts-option-prescription-medication')
        },
        {
            value: 'professional-therapy',
            label: t('past-attempts-option-professional-therapy'),
        },
        {
            value: 'none',
            label: t('past-attempts-option-none'),
        }
    ];

    const handleClick = () => {
        const selectedPageValues = getSelectedPageValues(pageValue);
        const selectOption = getMultiChoiceButtonSelectOptions(selectedPageValues, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('past-attempts-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue as IMultiChoiceButtonOption);
    };

    return (
        <>
            <Heading title={t('past-attempts-title')} subtitle={t('past-attempts-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
