import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { ErrorBlock } from 'components/paywall/error-block';
import { PrimerIOPaywall } from 'components/paywall/PrimerIO';

import { Cart } from './cart';
import { OrderDetails } from './order-details';
import { BreadcrumbsNavigation } from './breadcrumbs-navigation';
import { AccountForm, isValidAccountForm } from './account-form';
import { ShippingAddressForm, isValidShippingAddressForm, getShippingAddressDetails } from './shipping-address-form';
import { VerifiedForm } from './verified-form';

import { usePrimerIOPayment } from './use-primerio-payment.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { UserExistsMessage } from 'pages/checkout/user-exists-message';
import { PrimerLoader } from 'pages/checkout/primer-loader';

import { useStyles } from './styles';

export const Checkout = () => {
    const { t } = useTranslation();

    const {
        pageWrapper,
        checkoutContentWrapper,
        cartWrapper,
        shippingAddressFormDetailsWrapper,
        paymentWrapper,
        paymentWrapperVisible,
        title,
        primerLoadingWrapper,
        transparentContainer,
    } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const accountFormValid = isValidAccountForm();
    const shippingFormValid = isValidShippingAddressForm();

    const getShippingAddress = () => {
        const { streetAddress, city, state, zipCode } = getShippingAddressDetails();

        return `${streetAddress}, ${city}, ${state}, ${zipCode}`;
    };

    const [activeSection, setActiveSection] = useState(accountFormValid && shippingFormValid ? BreadcrumbsNavigationKeys.checkout : BreadcrumbsNavigationKeys.account);

    const [accountFormVerified, setAccountFormVerified] = useState(accountFormValid);
    const [shippingAddressFormVerified, setShippingAddressFormVerified] = useState(shippingFormValid);
    const [address, setAddress] = useState(shippingFormValid ? getShippingAddress() : '');

    const {
        plan,
        email,
        error,
        createSubscriptionLoadingRequest,
        onCheckoutFail,
        onBeforePaymentCreate,
        onCheckoutComplete,
    } = usePrimerIOPayment();

    const [showUserExistsMessagePrimer, setShowUserExistsMessagePrimer] = useState<boolean>(false);

    const handleAccountFormSubmit = () => {
        setAccountFormVerified(true);
        setActiveSection(BreadcrumbsNavigationKeys.checkout);
    };

    const handleAccountFormEdit = () => {
        setAccountFormVerified(false);
        setActiveSection(BreadcrumbsNavigationKeys.account);
    };

    const handleShippingAddressFormSubmit = (address: string) => {
        setAddress(address);
        setShippingAddressFormVerified(true);
    };

    const handleShippingAddressFormEdit = () => {
        setShippingAddressFormVerified(false);
    };

    const translationPaywall = {
        creditCard: {
            generalError: t('checkout-general-error'),
            creditCardForm: {
                input: {
                    namePlaceholder: t('checkout-credit-card-name-placeholder'),
                    cardNumberPlaceholder: t('checkout-credit-card-card-number-placeholder'),
                    cardExpiryPlaceholder: t('checkout-credit-card-card-expiry-placeholder'),
                    cardCvvPlaceholder: t('checkout-credit-card-card-cvv-placeholder'),
                },
                submitButtonText: t('checkout-credit-card-submit-button-text'),
            },
        },
    };

    const [primerLoadingRequest, setPrimerLoadingRequest] = useState(false);

    return (
        <>
            {!isDesktop && <Cart isDesktop={isDesktop} />}

            <div className={pageWrapper}>

                <div className={checkoutContentWrapper}>
                    <div>

                        <BreadcrumbsNavigation activeSection={activeSection} />

                        {!accountFormVerified && <AccountForm handleSubmit={handleAccountFormSubmit} />}
                        {accountFormVerified &&
                            <VerifiedForm title={t('checkout-account-form-verified-form-title')} text={email}
                                          handleEdit={handleAccountFormEdit} />}

                        {accountFormVerified &&
                            <>
                                {!shippingAddressFormVerified &&
                                    <ShippingAddressForm handleSubmit={handleShippingAddressFormSubmit} />}

                                {shippingAddressFormVerified &&
                                    <div className={shippingAddressFormDetailsWrapper}>
                                        <VerifiedForm title={t('checkout-shipping-address-verified-form-title')}
                                                      text={address} handleEdit={handleShippingAddressFormEdit} />
                                    </div>
                                }
                            </>
                        }

                        {error && <ErrorBlock error={error} />}

                        <div className={`${paymentWrapper} ${accountFormVerified && shippingAddressFormVerified && paymentWrapperVisible}`}>
                            <h1 className={title}>
                                3. Payment method
                            </h1>

                            {!showUserExistsMessagePrimer && accountFormVerified && shippingAddressFormVerified &&
                                <div className={`${(createSubscriptionLoadingRequest || primerLoadingRequest) && primerLoadingWrapper} 
                                                ${createSubscriptionLoadingRequest && transparentContainer}`}>

                                    {createSubscriptionLoadingRequest && <PrimerLoader />}

                                    <PrimerIOPaywall
                                        email={email}
                                        amount={plan.amount}
                                        currencyCode={plan.currencyCode}
                                        onError={onCheckoutFail}
                                        onBeforePaymentCreate={onBeforePaymentCreate}
                                        onPaymentCreationStart={setPrimerLoadingRequest}
                                        onCheckoutComplete={onCheckoutComplete}
                                        onCheckoutFail={onCheckoutFail}
                                        translation={translationPaywall.creditCard}
                                        setShowUserExistsMessagePrimer={setShowUserExistsMessagePrimer}
                                    />
                                </div>
                            }

                            {showUserExistsMessagePrimer && <UserExistsMessage />}
                        </div>

                        <OrderDetails />

                    </div>
                </div>

                {isDesktop && <div className={cartWrapper}><Cart isDesktop={isDesktop} /></div>}
            </div>
        </>
    );
};
