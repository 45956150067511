import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Heading,
    ISingleChoiceButtonOption,
    SingleChoiceButton, SingleChoicesButtonLabel,
} from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { useStyles } from './styles';

export const BodyGender = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();
    const { pageConfiguration } = useContext(BaseContext);

    const { choiceButtonWrapper} = useStyles();

    const pageKey = LocalStorageKeys[RoutePath.BodyGender];

    const { pageValue: bodyGender, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-gender-title'), value: option.label },
        });
        setPageValue(option.value);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const pageImages = useBackgroundImageService([
        'svg/body-gender/female-gender.svg',
        'svg/body-gender/male-gender.svg',
    ]);

    const options = [
        { value: 'female', label: t('body-gender-option-female'), image: pageImages[0] },
        { value: 'male', label: t('body-gender-option-male'), image: pageImages[1] },
    ];

    return (
        <>
            <Heading title={t('body-gender-title')} subtitle={t('body-gender-description')} />

            <div className={choiceButtonWrapper}>
                    {options.map((option) => (
                        <SingleChoiceButton
                            key={option.value}
                            option={option}
                            selected={option.value === bodyGender}
                            onClick={() => handleClick(option)}>
                            <SingleChoicesButtonLabel label={option.label} />
                            <img src={option.image} alt='' />
                        </SingleChoiceButton>
                    ))}
            </div>
        </>
    );
};
