import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    accountFormWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '560px'
    },
    title: {
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'left',
    },
    multipleFieldsWrapper: {
        display: 'flex',
        width: '100%',
        gap: '16px',
        maxWidth: '560px',
        margin: '0 auto',
        '& div': {
            width: '100%',
        }
    },
    continueToCheckoutButton: {
        ...nextButton.nextButton,
        margin: '16px 0 20px',

        '&:active': {
            ...nextButton.nextButtonFocus,
        },

        '&:disabled': {
            ...nextButton.nextButtonDisabled,
        }
    },
    error: {
        color: '#FF4D2B',
        fontSize: '15px',
        lineHeight: '20px',
        fontWeight: 500,
    },
    '@media (min-width: 768px)' : {
        title: {
            margin: '0 0 8px'
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            }
        }
    }
}));
