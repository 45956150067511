import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { BaseContext } from 'context/base.context';

import { ScrollToTop } from 'helpers/scroll-to-top';

import { AppThemeProvider } from 'context/theme-provider';
import { LastLocationProvider } from 'context/last-location-provider';

import { RouterConfig } from 'routes';

import { PageConfigurationService } from 'services/page-configuration.service';

import { Content } from '../content';

import { useUserGeolocation } from 'hooks/use-user-geolocation.hook';
import { ImagePreloadingProvider } from '@ruby-labs/ui-core-kit';
import { BackgroundLoadingImages } from 'services/background-images.service';

export const pageConfiguration = new PageConfigurationService();

function Index() {
    const { countryCode } = useUserGeolocation();

    useEffect(() => {

        if (pageConfiguration) {
            pageConfiguration.event('RxWeightFunnelStartViewed');
        }
    }, []);

    return (
        <BaseContext.Provider value={{
            countryCode,
            pageConfiguration,
        }}>
            <ImagePreloadingProvider
                images={BackgroundLoadingImages().images.map(i => ({ key: i.key, pathUrl: `${window.location.origin}${i.pathUrl}` }))}>
                <AppThemeProvider>
                    <BrowserRouter>
                        <ScrollToTop />
                        <Content>
                            <LastLocationProvider>
                                <RouterConfig />
                            </LastLocationProvider>
                        </Content>
                    </BrowserRouter>
                </AppThemeProvider>
            </ImagePreloadingProvider>
        </BaseContext.Provider>
    );
}

export default Index;
