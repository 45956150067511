import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    medicalDirectorCardWrapper: {
        width: '146px',
        display: 'flex',
        alignSelf: 'center',
        gap: '10px',
        padding: '16px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        marginBottom: '20px',

        '& img': {
            width: '114px',
            borderRadius: '4px'
        }
    },
    '@media (min-width: 768px)': {
        medicalDirectorCardWrapper: {
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
            textAlign: 'center',
            flexShrink: 0,
            width: '278px',
            minWidth: 'unset',
            padding: '12px',
            marginRight: '5px',
            marginTop: 0,
            gap: '16px',

            '& img': {
                width: '134px'
            }
        }
    }
});
