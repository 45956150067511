import React, {FC, ReactNode} from 'react';

import { useStyles } from './styles';

export interface BannerProps {
    children: string | ReactNode;
}

export const Banner: FC<BannerProps> = ({ children }) => {
    const { banner } = useStyles();

    return (
        <div className={banner}>
            {children}
        </div>
    );
};
