import { useContext, useEffect, useState } from 'react';
import { PreloadImageServiceInstanceCTX } from 'services/preload-image.service';

export const useBackgroundImageService = (items: string[]): string[] => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        async function loadImages() {
            for (const [i, item] of items.entries()) {
                if (item) {
                    const img = await getImageByLocalPath(item);
                    setImages(images => {
                        const newImages = [...images];
                        newImages[i] = img as string;
                        return newImages;
                    });
                }
            }
        }

        loadImages().catch(error => {
            console.error('Error while loading images:', error);
        });
    }, []);

    return images;
}
