import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    orderDetailsListWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '12px'
    },
    orderDetailsListItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '12px',

        '&:last-child': {
            marginBottom: 0
        },

        '& img': {
            marginRight: '8px',
            alignSelf: 'center'
        }
    },
    text: {
        fontSize: '15px',
        lineHeight: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#4D4D4D',
        margin: 'unset'
    },
    '@media (min-width: 768px)' : {
        orderDetailsListWrapper: {
            marginTop: '16px'
        }
    }
});
