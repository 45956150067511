import React from 'react';
import { Trans } from 'react-i18next';

import { Banner } from 'components/banner';

import { useStyles } from './styles';

export const TipBanner = () => {
    const { tipBannerText } = useStyles();

    return (
        <Banner>
            <h4 className={tipBannerText}>
                <Trans i18nKey={'medication-banner-title'} components={[<strong></strong>]} />
            </h4>
        </Banner>
    );
};
