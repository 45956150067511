export const FUNNEL_VERSION = 'v1';

export const APP_NAME = `Able_RX_UK_${FUNNEL_VERSION}`;

export const APP_SOURCE_MOBILE = 'mobile';

export const APP_SOURCE_DESKTOP = 'desktop';

export const APP_PLATFORM_IOS = 'ios';

export const APP_PLATFORM_ANDROID = 'android';

export const APP_TYPE_APP = 'app';

export const APP_TYPE_PWA = 'pwa';
