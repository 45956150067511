// node modules
import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    nextButton: {
        ...nextButton.nextButton,
        '&:active': {
            ...nextButton.nextButtonHovered,
        },
        '&:disabled': {
            ...nextButton.nextButtonDisabled,
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
