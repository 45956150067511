import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    multiChoiceWrapper: {

        '& button': {
            background: 'none',
            border: 'none',
            color: '#010101',
            '-webkit-tap-highlight-color': 'transparent',

            '&:hover': {
                background: 'none!important'
            }
        },

        '& label > span': {
            background: '#E8F5FD',
        }
    }
});
