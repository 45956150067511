import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    footer: {
        position: 'relative',
        width: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#F6F6F6',
        borderTop: '1px solid #C5C5D1'
    },
    footerContent: {
        width: '85%',
        maxWidth: '406px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 400,
        letterSpacing: '-0.2px',
        padding: '16px',
        marginRight: 'auto',
        marginLeft: 'auto',
        color: '#010101',
    },
    links: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '8px',


        '& a': {
            color: '#010101',
            textDecoration: 'underline',
            textUnderlinePosition: 'under'
        }
    },
    contentBlock: {
        width: '100%',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    companyInfo: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media only screen and (max-width: 280px)' : {
        footerContent: {
            fontSize: '10px'
        }
    },
    '@media only screen and (max-width: 375px)' : {
        footerContent: {
            width: '95%'
        }
    },
    '@media only screen and (max-width: 320px)' : {
        footerContent: {
            width: '100%'
        }
    },
    '@media only screen and (min-width: 768px)' : {
        footerContent: {
            width: '80%',
            marginRight: 'auto',
            marginLeft: 'auto',
            padding: '32px 0',
            fontSize: '14px',
            lineHeight: '21px'
        },
        contentBlock: {
            width: 'auto'
        },
        companyInfo: {
            justifyContent: 'space-between',
            flexDirection: 'row',

            '& span:last-child::before': {
                content: '", "'
            }
        },
        links: {
            marginTop: '12px'
        }
    }
});
