import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    customerCardWrapper: {
        height: '100%',
        width: '100%',
        minHeight: '340px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        borderRadius: '12px',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        '-webkit-tap-highlight-color': 'transparent',
    },
    customerName: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
        textAlign: 'left',
        color: '#FFFFFF',
        margin: '12px 0',
    },
    customerWeightLoss: {
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'left',
        color: '#EB731A',
        letterSpacing: '0.0008em',
        margin: 'unset',
    }
});
