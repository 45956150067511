import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, getSelectedPageValues } from 'helpers/multichoice-button-options';

export const EthnicBackground = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.EthnicBackground];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'white',
            label: t('ethnic-background-option-white')
        },
        {
            value: 'asian',
            label: t('ethnic-background-option-asian')
        },
        {
            value: 'middle-eastern',
            label: t('ethnic-background-option-middle-eastern')
        },
        {
            value: 'african',
            label: t('ethnic-background-option-african')
        },
        {
            value: 'none',
            label: t('ethnic-background-option-none'),
        }
    ];

    const handleClick = () => {
        const selectedPageValues = getSelectedPageValues(pageValue);
        const selectOption = getMultiChoiceButtonSelectOptions(selectedPageValues, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('ethnic-background-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue as IMultiChoiceButtonOption);
    };

    return (
        <>
            <Heading title={t('ethnic-background-title')} subtitle={t('ethnic-background-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
