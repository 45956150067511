import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { GoogleStreetAddress } from './index';

import { useStyles } from 'components/input/styles';
import { useStyles as ValidatedUseStyles } from 'components/validated-input/styles';
import { useStyles as GoogleStreetAddressAutoCompleteUseStyles } from './styles';

interface GoogleStreetAddressAutoCompleteProps {
    value: string;
    onChange: (address: string) => void;
    isError: boolean;
    streetAddressInvalid?: null | boolean;
    handleSelectStreetAddress: ({ result, name, googleValue }: GoogleStreetAddress) => void;
    nightTheme?: boolean;
}

// @description: https://maps.googleapis.com script should be installed
export const GoogleStreetAddressAutoComplete: FC<GoogleStreetAddressAutoCompleteProps> = ({ value, onChange, isError, streetAddressInvalid, handleSelectStreetAddress, nightTheme }) => {
    const { t } = useTranslation();

    const {
        inputAddressInput,
        input,
        inputDark,
        inputLabelClass,
        inputActiveClass,
        inputActiveClassDark,
        inputErrorClass,
        inputLabelActiveClass,
        inputLabelErrorClass,
        inputAddressInputDark,
        inputLabelDark,
    } = useStyles();

    const { validatedInputWrapper } = ValidatedUseStyles();
    const { googleAutocompleteBlock, description, suggestionItem, suggestionItemActive, inputError } = GoogleStreetAddressAutoCompleteUseStyles();

    const options = {
        componentRestrictions: { country: 'gb' },
        fields: ['address_components', 'name'],
        types: ['address'], // address || establishment
    };

    const [activeClass, setActiveClass] = useState(false);

    const handleFocus = () => {
        setActiveClass(true);
    };

    const handleBlur = () => {
        setActiveClass(false);
        handleSelectStreetAddress({ result: { address_components: [], formatted_address: '', place_id: '' }, name: value, googleValue: '' });
    };

    const isFieldHasError = isError || streetAddressInvalid;

    const combinedInputWrapperClass = `${inputAddressInput} ${activeClass ? nightTheme ? inputActiveClassDark : inputActiveClass : ''} ${isFieldHasError ? inputErrorClass : '' } ${nightTheme ? inputAddressInputDark : '' }`;

    const combinedInputClass = `${input} ${nightTheme ? inputDark : '' }`;

    const combinedInputLabelClass = `${inputLabelClass} ${value || activeClass ? inputLabelActiveClass : ''} ${isFieldHasError ? inputLabelErrorClass : '' } ${nightTheme ? inputLabelDark : '' }`;

    const handleChange = (address: string) => {
        onChange(address);
    };

    const handleSelect = (address: string) => {
        geocodeByAddress(address)
            .then((results: google.maps.GeocoderResult[]) => {
                console.log(results);

                const streetName = results[0].address_components.filter((addressComponent: google.maps.GeocoderAddressComponent) => {
                    return addressComponent.types.includes('street_number') || addressComponent.types.includes('route');
                }).map(a => a?.long_name).join(' ');

                handleSelectStreetAddress({ result: results[0], name: streetName, googleValue: address })
            });
    };

    const handleError = (status: string, _clearSuggestions: () => void) => {
        if (status === 'ZERO_RESULTS') {
            _clearSuggestions();
        }
    };

    return (
        <div className={validatedInputWrapper}>

            <PlacesAutocomplete
                value={value}
                onChange={handleChange}
                onSelect={handleSelect}
                onError={handleError}
                searchOptions={options}
                googleCallbackName={'init'}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                    return (
                        <div className={combinedInputWrapperClass}>
                            <p className={combinedInputLabelClass}>
                                {t('checkout-shipping-address-form-input-street-address')}
                            </p>
                            <input
                                {...getInputProps({
                                    placeholder: '',
                                    className: combinedInputClass,
                                })}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                            />
                            <div className={`${googleAutocompleteBlock}`} style={{display: activeClass ? 'block': 'none'}}>
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? suggestionItemActive
                                        : suggestionItem;
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                            })}
                                            key={suggestion.placeId}>
                                            <span className={description}>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                }
            </PlacesAutocomplete>

            {isError && <p className={inputError}>
                {t('checkout-input-error-empty-field')}
            </p>}
        </div>
    );
};
