import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalWindow } from 'components/modal-window';
import { Button } from 'components/button';

import { useStyles } from './styles';

export const CorrectionModalWindow = ({ address, handleClick }: { address: string, handleClick: () => void }): JSX.Element => {
    const { t } = useTranslation();

    const { modalWindowLoading } = useStyles();

    return (
        <ModalWindow wrapperClassName={modalWindowLoading}>
            <h1>
                {t('shipping-address-correction-modal-window-title')}
            </h1>

            <h2>
                {address}
            </h2>

            <Button typographyText={t('shipping-address-correction-modal-window-button')} onClick={handleClick} />
        </ModalWindow>
    );
};
