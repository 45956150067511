import React, { FC } from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { useStyles } from './styles';

interface BreadcrumbsNavigationProps {
    activeSection: string;
}

export const BreadcrumbsNavigation: FC<BreadcrumbsNavigationProps> = ({ activeSection }) => {
    const { sectionsList, listItem, sectionName, active } = useStyles();

    const pageImages = useBackgroundImageService([
        'svg/checkout/breadcrumbs-navigation/navigation-icon.svg'
    ]);

    const sections = [
        BreadcrumbsNavigationKeys.quiz,
        BreadcrumbsNavigationKeys.healthPlan,
        BreadcrumbsNavigationKeys.account,
        BreadcrumbsNavigationKeys.checkout
    ];

    return (
        <nav>
            <ol className={sectionsList}>
                {sections.map(section =>
                    <li className={listItem} key={section}>
                        <h4 className={`${sectionName} ${activeSection === section && active}`}>
                            {section}
                        </h4>
                        <img src={pageImages[0]} alt='Arrow Icon' />
                    </li>
                )}
            </ol>
        </nav>
    );
};
