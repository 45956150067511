import { Palette } from '../index';

export type ButtonType = {
    button: {
        background: Palette | string,
        position: string,
        padding: string,
        maxWidth: string,
        minWidth: string,
        width: string,
        height: string,
        border: string,
        color: Palette | string,
        borderRadius: string,
        cursor: string,
    }
    buttonHover?: {
        background?: string,
        color?: string,
    },
    buttonActive?: {
        background?: Palette | string
        color?: Palette | string,
        boxShadow?: string,
    },
    buttonDisabled: {
        cursor: string,
        background: Palette | string
        color: Palette | string,
    },
    buttonText: {
        fontSize: string,
        lineHeight: string,
        fontWeight: number
    }
}

export const ButtonStyles: ButtonType = {
    button: {
        background: '#E8F5FD',
        position: 'relative',
        padding: '12px 16px',
        maxWidth: '400px',
        minWidth: '120px',
        width: '100%',
        height: '48px',
        border: 'none',
        color: '#010101',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    buttonDisabled: {
        cursor: 'not-allowed',
        background: '#C5C5D1',
        color: '#FFFFFF',
    },
    buttonHover: {
        background: '#B7E3FF',
        color: '#010101',
    },
    buttonText: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 600,
    }
};
