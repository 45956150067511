import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    banner: {
        width: 'inherit',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#C6EFE2',
        position: 'sticky',
        top: '0',
        zIndex: 101,

        '@media (min-width: 768px)' : {
            height: '50px'
        }
    }
});
