import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { NextButton } from 'components/next-button';

export const UserExistsMessage = (): JSX.Element => {
    const navigate = useNavigate();

    const handleNextButtonClick = () => {
        navigate(`..${RoutePath.Welcome}`);
    };

    return (
        <div>
            <p>
                <Trans i18nKey='checkout-user-exists-message' />
            </p>

            <NextButton onClick={handleNextButtonClick} />
        </div>
    );
};
