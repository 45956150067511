import { AddressComponent, ConfirmationLevel, ValidationResult } from 'api/google-address-validation.api';

interface FieldValidateResponse {
    valid: boolean;
    changed: boolean;
    text: string;
    value: string;
}

interface ValidateResponse {
    streetAddress: FieldValidateResponse;
    address2: FieldValidateResponse;
    city: FieldValidateResponse;
    county: FieldValidateResponse;
    postCode: FieldValidateResponse;
}

const isValid = (level: ConfirmationLevel | undefined): boolean => {
    return level !== ConfirmationLevel.UNCONFIRMED_AND_SUSPICIOUS;
};

const isChanged = (component: AddressComponent): boolean => {
    return component.replaced || component.inferred || component.spellCorrected || false;
};

const getFieldValidateResponse = (addressComponents: AddressComponent[], componentTypes: string[], combineText: boolean = false): FieldValidateResponse => {
    const response = {
        valid: true,
        changed: false,
        text: '',
        value: '',
    };

    const filedAddressComponents = addressComponents.filter(ac => componentTypes.includes(ac.componentType));

    console.log('filedAddressComponents', filedAddressComponents);

    response.valid = filedAddressComponents.map(ac => ac.confirmationLevel).every(isValid);

    response.changed = filedAddressComponents.some(isChanged);
    if (response.changed) {
        response.text = combineText ? filedAddressComponents.map(ac => ac.componentName.text).join(' ') : filedAddressComponents[0].componentName.text;
    }

    if (filedAddressComponents?.length) {
        response.value = combineText ? filedAddressComponents.map(ac => ac.componentName.text).join(' ') : filedAddressComponents[0].componentName.text;
    }

    return response;
};

export const validateResponse = ({ address }: ValidationResult): ValidateResponse => {
    const postalCode = getFieldValidateResponse(address.addressComponents, ['postal_code']);
    const postalCodeSuffix = getFieldValidateResponse(address.addressComponents, ['postal_code_suffix']);

    const postCode = postalCode;

    if (postalCodeSuffix.value.length) {
        postCode.text = `${postalCode.text || postalCode.value}-${postalCodeSuffix.value}`;
        postCode.value = `${postalCode.value}-${postalCodeSuffix.value}`;
    }

    return {
        streetAddress: getFieldValidateResponse(address.addressComponents, ['route', 'street_number'], true),
        address2: getFieldValidateResponse(address.addressComponents, ['subpremise']),
        city: getFieldValidateResponse(address.addressComponents, ['postal_town', 'administrative_area_level_3']),
        county: getFieldValidateResponse(address.addressComponents, ['administrative_area_level_2']),
        postCode,
    };
};
