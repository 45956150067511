import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export type CustomerCardType = {
    name: string;
    backgroundPhoto: string;
    review: string;
};

type CustomerCardProps = {
    customerCard: CustomerCardType;
    setCurrentVideoName: (value: string | null) => void;
};

export const CustomerCard: FC<CustomerCardProps> = ({ customerCard, setCurrentVideoName }) => {
    const { t } = useTranslation();

    const { name, review } = customerCard;
    const {
        customerCardWrapper,
        customerName,
        customerWeightLoss,
    } = useStyles();

    const pageImages = useBackgroundImageService(['svg/medication/video-player/play.svg']);

    return (
        <div className={customerCardWrapper} onClick={() => setCurrentVideoName(name)}>
            <img src={pageImages[0]} alt={'Play Icon'} />
            <p className={customerName}>
                {t(name)}
            </p>
            <p className={customerWeightLoss}>
                {t(review)}
            </p>
        </div>
    );
};
