import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton, button }: ThemeProps) => ({
    title: {
        textAlign: 'center',
        margin: '32px 0 16px',

        '@media (min-width: 768px)' : {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '40px 0'
        }
    },
    subtitle: {
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'center',
        margin: '16px 0 0',

        '@media (min-width: 768px)' : {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '16px 0 40px',
        }
    },
    iconWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: '32px 0 0',

        '@media (min-width: 768px)' : {
            margin: '80px 0 0'
        },

        '@media (max-width: 320px)' : {
            '& img': {
                width: '100%'
            },
        }
    },
    continueButtonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    continueButton: {
        ...button.button,
        ...nextButton.nextButton,
        margin: '32px 0 24px',

        '&:active': {
            ...nextButton.nextButtonHovered,
        },

        '@media (min-width: 768px)' : {
            margin: '40px 0 32px',
        },
    },
    link: {
        textDecoration: 'none',
        color: '#FFFFFF',

        '& > p': {
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 600,
        }
    },
    changeAnswersButton: {
        ...nextButton.nextButton,
        margin: '-12px 0 24px',
        background: 'none',
        color: '#EB731A',

        '@media (min-width: 768px)' : {
            margin: '-16px 0 24px',
        },
    },
    '@media (hover: hover)': {
        continueButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            }
        }
    }
}));
