import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export const LoseWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.LoseWeight];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        setPageValue(option);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('lose-weight-title'), value: option.label },
        });

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        {
            value: 'whole-life',
            label: t('lose-weight-option-whole-life'),
        },
        {
            value: 'less-than-six-months',
            label: t('lose-weight-option-less-than-six-months'),
        },
        {
            value: 'six-twelve-months',
            label: t('lose-weight-option-six-twelve-months'),
        },
        {
            value: 'several-years',
            label: t('lose-weight-option-several-years'),
        },
        {
            value: 'never',
            label: t('lose-weight-option-never'),
        }
    ];

    return (
        <>
            <Heading title={t('lose-weight-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
