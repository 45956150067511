export const RoutePath = {
    MainPage: '/',
    EthnicBackground: '/ethnic-background',
    BodyGender: '/body-gender',
    LoseWeight: '/lose-weight',
    FamilyWeight: '/family-weight',
    PastAttempts: '/past-attempts',
    ExerciseHours: '/exercise-hours',
    LoseWeightReason: '/lose-weight-reason',
    WeightFactors: '/weight-factors',
    PrescriptionMedications: '/prescription-medications',
    BodyHeight: '/body-height',
    BodyWeight: '/body-weight',
    BodyIdealWeight: '/body-ideal-weight',
    HealthCondition: '/health-condition',
    HealthConditionPart2: '/health-condition-part-2',
    MedicalCondition: '/medical-condition',
    MedicalConditionPart2: '/medical-condition-part-2',
    MedicationIntake: '/medication-intake',
    MedicationIntakePart2: '/medication-intake-part-2',
    Consent: '/consent',
    BloodPressureInformation: '/blood-pressure-information',
    GeneralPractitionerInformation: '/general-practitioner-information',
    Email: '/email',
    DeclineUser: '/without-medications',
    Medication: '/medication',
    Checkout: '/checkout',
    Congrats: '/congrats',
    Welcome: '/welcome',

    // Page for opening in WebView
    ShippingAddressEmbedded: '/shipping-address-embedded',
};
