import React from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export const OrderDetails = () => {
    const { orderDetailsListWrapper, orderDetailsListItem, text } = useStyles();

    const pageImages = useBackgroundImageService([
        'svg/checkout/order-details/shipping-icon.svg',
        'svg/checkout/order-details/cancel-icon.svg',
        'svg/checkout/order-details/medical-advisor-icon.svg'
    ]);

    return (
        <div className={orderDetailsListWrapper}>
            <div className={orderDetailsListItem}>
                <img src={pageImages[0]} alt='Shipping Icon' />
                <p className={text}>
                    <Trans i18nKey='checkout-order-details-free-shipping' />
                </p>
            </div>
            <div className={orderDetailsListItem}>
                <img src={pageImages[1]} alt='Cancel Icon' />
                <p className={text}>
                    <Trans i18nKey='checkout-order-details-cancel-anytime' />
                </p>
            </div>
            <div className={orderDetailsListItem}>
                <img src={pageImages[2]} alt='Medical Advisor Icon' />
                <p className={text}>
                    <Trans i18nKey='checkout-order-details-medical-advisor' />
                </p>
            </div>
        </div>
    );
};
