import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    modalWindowLoading: {
        zIndex: 102,
        backdropFilter: 'blur(2px)',

        '& > div': {
            padding: '24px',
        },

        '& h1': {
            textAlign: 'center',
            margin: '0 0 16px',
        },

        '& h2': {
            textAlign: 'center',
            margin: '0 0 32px',
        }
    }
});
