import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { isEmpty } from 'helpers';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { RoutePath } from 'routes/route-path.constant';
import { pageConfiguration } from 'containers/index';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

import { ValidatedInput } from 'components/validated-input';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

interface AccountFormProps {
    handleSubmit: (email: string) => void
}

export const AccountForm: FC<AccountFormProps> = ({ handleSubmit }) => {
    const { t } = useTranslation();

    const { accountFormWrapper, title, multipleFieldsWrapper, continueToCheckoutButton, error } = useStyles();

    const key = MultipleLocalStorageKeys[RoutePath.Checkout];

    const { pageValue: firstName, setPageValue: setFirstName } = useLocalStorage({
        key: key.firstName as string,
        defaultValue: '',
    });

    const { pageValue: lastName, setPageValue: setLastName } = useLocalStorage({
        key: key.lastName as string,
        defaultValue: '',
    });

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const { pageValue: password, setPageValue: setPassword } = useLocalStorage({
        key: key.password as string,
        defaultValue: '',
    });

    const { pageValue: dateOfBirth, setPageValue: setDateOfBirth } = useLocalStorage({
        key: key.dateOfBirth as string,
        defaultValue: '',
    });

    const { pageValue: phone, setPageValue: setPhone } = useLocalStorage({
        key: key.phone as string,
        defaultValue: '',
    });

    const onFirstNameChange = (e: any) => { setFirstName(e.target.value); };

    const onLastNameChange = (e: any) => { setLastName(e.target.value); };

    const onEmailChange = (e: any) => { setEmail(e.target.value); };

    const onPasswordChange = (e: any) => { setPassword(e.target.value); };

    const onDateOfBirthChange = (e: any) => {
        let value = e.target.value.trim();

        const dateValue = value.replace(/[^\d]/g, '');

        const dateNumberRegex = /^(\d{0,2})?(\d{0,2})?(\d{0,4})?(\d+)?/;

        const matches = dateValue.match(dateNumberRegex);

        if (!dateValue) {
            setDateOfBirth('');
        } else {
            let phoneInnerValue = `${matches[1]}`;

            if (matches[2]) {
                phoneInnerValue += '/' + matches[2];
            }

            if (matches[3]) {
                phoneInnerValue += '/' + matches[3];
            }
            if (matches[4]) {
                phoneInnerValue += '' + matches[4];
            }

            setAgeError(false);
            setDateOfBirth(phoneInnerValue);
        }
    };

    const onPhoneChange = (e: any) => { setPhone(e.target.value); };

    const [validationListener, setValidationListener] = useState(false);

    const [inputErrors, setInputErrors] = useState<{[index: string]: any }>({});

    const handleError = (error: boolean, key: string) => {
        setInputErrors((prevState) => ({
            ...prevState,
            [key]: error,
        }));
    };

    const onFirstNameError = (error: boolean) => {
        handleError(error, 'firstName');
    };

    const onLastNameError = (error: boolean) => {
        handleError(error, 'lastName');
    };

    const onEmailError = (error: boolean) => {
        handleError(error, 'email');
    };

    const onPasswordError = (error: boolean) => {
        handleError(error, 'password');
    };

    const onDateOfBirthError = (error: boolean) => {
        handleError(error, 'bday');
    };

    const onPhoneError = (error: boolean) => {
        handleError(error, 'phone');
    };

    const [ageError, setAgeError] = useState(false);

    const handleFormSubmit = () => {
        setValidationListener(true);

        // Check age
        const birthDate = dayjs(dateOfBirth, "DD/MM/YYYY");
        const today = dayjs();
        const age = today.diff(birthDate, 'year');

        if (!isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(email) &&
            !isEmpty(password) && !isEmpty(dateOfBirth) && !isEmpty(phone) &&
            !Object.keys(inputErrors).some((key: string) => inputErrors[key]) && age >= 18) {
            handleSubmit(email);
        } else if (age < 18) {
            setAgeError(true);
        } else {
            return;
        }

        pageConfiguration.event('RxUKWeightContinueToCheckoutClicked');
    };

    return (
        <div className={accountFormWrapper}>

            <h1 className={title}>
                <Trans i18nKey='checkout-account-form-title' />
            </h1>

            <div className={multipleFieldsWrapper}>
                <div>
                    <ValidatedInput
                        autoComplete='given-name'
                        value={firstName}
                        onChange={onFirstNameChange}
                        onError={onFirstNameError}
                        validationQuery={{ type: { value: 'emptyString', errorMessage: t('checkout-input-error-empty-field') } }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-account-form-input-first-name')} />
                </div>

                <div>
                    <ValidatedInput
                        autoComplete='family-name'
                        value={lastName}
                        onChange={onLastNameChange}
                        onError={onLastNameError}
                        validationQuery={{ type: { value: 'emptyString', errorMessage: t('checkout-input-error-empty-field') } }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-account-form-input-last-name')} />
                </div>
            </div>

            <div>
                <ValidatedInput
                    type='email'
                    autoComplete='email'
                    value={email}
                    onChange={onEmailChange}
                    onError={onEmailError}
                    validationQuery={{
                        type: {
                            value: 'email',
                            errorMessage: t('email-input-error-message'),
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-email')} />
            </div>

            <div>
                <ValidatedInput
                    type='text'
                    value={password}
                    onChange={onPasswordChange}
                    onError={onPasswordError}
                    validationQuery={{ type: { value: 'emptyString', errorMessage: t('checkout-input-error-empty-field') } }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-password')} />
            </div>

            <div>
                <ValidatedInput
                    value={dateOfBirth}
                    autoComplete='bday'
                    onChange={onDateOfBirthChange}
                    onError={onDateOfBirthError}
                    maxLength={10}
                    validationQuery={{
                        type: {
                            value: 'date',
                            format: 'DD/MM/YYYY',
                            errorMessage: 'Invalid date',
                        }
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-date-of-birth')} />
            </div>

            <div>
                <ValidatedInput
                    type='tel'
                    value={phone}
                    onChange={onPhoneChange}
                    onError={onPhoneError}
                    maxLength={11}
                    validationQuery={{
                        type: {
                            value: 'phone',
                            pattern: /^[0-9]{11}$/,
                            errorMessage: 'Invalid phone number',
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-account-form-input-phone')} />
            </div>

            {ageError &&
                <p className={error}>
                    {t('account-form-age-validation-error')}
                </p>
            }

            <NextButton className={continueToCheckoutButton} onClick={handleFormSubmit} typographyText={t('checkout-account-form-button-next')} />
        </div>
    );
};

export const getCustomerDetails = () => {
    const key = MultipleLocalStorageKeys[RoutePath.Checkout];

    const firstName = localStorage.getItem(key.firstName as string) || '';
    const lastName = localStorage.getItem(key.lastName as string) || '';
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email] as string) || '';
    const password = localStorage.getItem(key.password as string) || '';
    const dateOfBirth = localStorage.getItem(key.dateOfBirth as string) || '';
    const phone = localStorage.getItem(key.phone as string) || '';

    return { firstName, lastName, email, password, dateOfBirth, phone };
};

export const isValidAccountForm = () => {
    const { firstName, lastName, email, password, dateOfBirth, phone } = getCustomerDetails();

    return !isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(email) && !isEmpty(password) && !isEmpty(dateOfBirth) && !isEmpty(phone);
};
