import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { PLAN } from 'constants/plan';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { useStyles } from './styles';

export const Program = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();

    const {
        programWrapper,
        programTitle,
        medicationWrapper,
        programItemBlock,
        programItem,
        circle,
        infoWrapper,
        medicationOptions,
        priceBlock,
        nextButtonStyles
    } = useStyles();

    const pageImages = useBackgroundImageService([
        'webp/medication/medications-pack.webp',
        'svg/medication/calendar.svg',
        'svg/medication/refund.svg',
        'svg/medication/medication-bottle.svg',
        'svg/medication/delivery.svg'
    ]);

    const isDesktop = useDesktopMediaWatcher();

    const subscriptionPrice = `£${PLAN.subscriptionAmount / 100}`;

    return (
        <div className={programWrapper}>
            <h1 className={programTitle}>
                {t('medication-program-title')}
            </h1>

            <div className={medicationWrapper}>
                <div>
                    <img src={pageImages[0]} alt='Medications Pack' />

                    <div className={programItemBlock}>
                        <div className={programItem}>
                            <div className={circle}>1</div>
                            <p>
                                {t('medication-program-option-1')}
                            </p>
                        </div>
                        <div className={programItem}>
                            <div className={circle}>2</div>
                            <p>
                                {t('medication-program-option-2')}
                            </p>
                        </div>
                        <div className={programItem}>
                            <div className={circle}>3</div>
                            <p>
                                {t('medication-program-option-3')}
                            </p>
                        </div>
                    </div>
                </div>

                <div className={infoWrapper}>
                    <p>
                        {t('medication-program-info-block-title')}
                    </p>
                    <p>
                        {t('medication-program-info-block-description')}
                    </p>
                </div>
            </div>

            <div className={medicationOptions}>
                <div className={programItem}>
                    <img src={pageImages[1]} alt='Calendar' />
                    <p>
                        {t('medication-program-option-4')}
                    </p>
                </div>
                <div className={programItem}>
                    <img src={pageImages[2]} alt='Refund' />
                    <p>
                        {t('medication-program-option-5')}
                    </p>
                </div>
                <div className={programItem}>
                <img src={pageImages[3]} alt="Medication Cost" />
                    <p>
                        {t('medication-program-option-6')}
                    </p>
                </div>
                <div className={programItem}>
                    <img src={pageImages[4]} alt="Delivery" />
                    <p>
                        {t('medication-program-option-7')}
                    </p>
                </div>
            </div>

            <div className={priceBlock}>
                <h1>
                    {subscriptionPrice} <Trans i18nKey='medication-program-subscription' />
                </h1>
            </div>

            <FloatedButton floated={!isDesktop} withGradient>
                <NextButton
                    className={`${nextButtonStyles}`}
                    onClick={onClick}
                    typographyText={t('medication-program-button-next')} />
            </FloatedButton>

        </div>
    );
};
