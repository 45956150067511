import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    errorMessageClass: {},
    validatedInputWrapper: {
        maxWidth: '560px',
        margin: '0 auto',
    },
    inputErrorText: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#FF4D2B',
        margin: '4px 0 0 16px',
    }
});
