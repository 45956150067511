import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    videoPlayerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '-5%',
        right: 0,
        bottom: '-5%',
        left: 0,

        width: '100%',
        height: '110%',

        transition: 'opacity 0.4s',

        opacity: 1,
        background: 'rgba(0, 0, 0, .9)',
        zIndex: 102,
    },
    videoPlayerCrossIcon: {
        position: 'absolute',
        top: '7%',
        right: '4%',
        height: '1.3em',
        opacity: .8,
        zIndex: 4,

        '&:hover': {
            opacity: 1,
        }
    }
});
