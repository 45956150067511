import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
    welcomeMobileInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 16px',
    },
    title: {
        margin: 'unset'
    },
    subtitle: {
        fontSize: '16px',
        color: '#5E626B',
        margin: '16px 0 24px',
        padding: '0 0 24px',
        borderBottom: '1px solid #F0F0FF'
    },
    listWrapper: {
        maxWidth: '560px',
        marginBottom: '24px',
    },
    list: {
        listStyle: 'decimal',
        listStylePosition: 'outside',
    },
    listItem: {
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'left',
        wordBreak: 'break-word',
        margin: '0',

        '& li': {
            marginLeft: '28px',
        },

        '@media (max-width: 500px)' : {
            '& li': {
                marginLeft: '36px',
            }
        },

        '& a': {
            color: '#1375D6',
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
        }
    },
    suggestionIfUserExists: {
        textAlign: 'left',
        marginBottom: '12px',
    },
    info: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#5E626B',
        margin: '0 0 -4px'
    }
}));
