import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    customerVideoReviewsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'relative',
        padding: '32px 0',
        backgroundColor: '#C1D0F6',
    },
    title: {
        fontSize: '24px',
        lineHeight: '34px',
        textAlign: 'left',
        margin: '0 0 32px 24px',
    },
    customerReviewsSwiper: {
        position: 'relative',
        width: '100vw',
        visibility: 'visible',

        '& .splide__track': {
            maskImage: 'linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.9) 14%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 84%, rgba(255, 255, 255, 0.2) 100%)',
        },

        '& .splide__arrow.splide__arrow--prev': {
            position: 'absolute',
            top: '55%',
            cursor: 'pointer',
            opacity: 1,
            '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',

            '& img': {
                width: '32px',
                height: '32px'
            }
        },

        '& .splide__arrow.splide__arrow--next': {
            position: 'absolute',
            top: '55%',
            cursor: 'pointer',
            opacity: 1,
            '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',

            '& img': {
                width: '32px',
                height: '32px'
            }
        }
    },
    swiperSlide: {
        height: '100%',
        cursor: 'pointer',
        minHeight: '340px',
        minWidth: '236px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        borderRadius: '12px',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '0 16px 16px',
        '-webkit-backface-visibility': 'hidden',
        '-webkit-transform': 'translate3d(0, 0, 0)'
    },
    '@media (min-width: 768px)': {
        customerVideoReviewsWrapper: {
            alignItems: 'center',
            padding: '80px 0'
        },
        title: {
            fontSize: '34px',
            lineHeight: '44px',
            textAlign: 'center',
            margin: '0 0 56px'
        },
        customerReviewsSwiper: {
            width: '90%',
            maxWidth: '1512px',

            '& .splide__track': {
                width: '85%',
                margin: '0 auto',
                maskImage: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 84%, rgba(255, 255, 255, 0.1) 100%)',
            },

            '& .splide__arrow.splide__arrow--prev': {

                '& img': {
                    width: '40px',
                    height: '40px'
                }
            },

            '& .splide__arrow.splide__arrow--next': {

                '& img': {
                    width: '40px',
                    height: '40px'
                }
            }
        }
    },
    '@media screen and (min-width: 1200px)': {
        customerReviewsSwiper: {

            '& .splide__track': {
                maskImage: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 92%, rgba(255, 255, 255, 0.1) 100%)',
            },

            '& .splide__arrow.splide__arrow--prev': {
                left: '3rem',
                width: '1rem'
            },

            '& .splide__arrow.splide__arrow--next': {
                right: '3rem',
                width: '1rem'
            }
        }
    }
});
