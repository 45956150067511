import React, { ChangeEvent, FC, FocusEvent, useRef, useState } from 'react';

import closeIconSvg from '../../assets/images/svg/close.svg';

import { useStyles } from './styles';

const isValueSet = (value: string | number | undefined, type = 'text'): boolean => {
    if (type === 'number') {
        return Number.isInteger(parseInt(value as string));
    }
    return !!value;
};

export interface InputProps {
    value?: string | number;
    type?: string;
    label?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;

    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement> | Event) => void;

    inputWrapperClassName?: string;
    inputLabelClassName?: string;


    inputClassName?: string;

    error?: boolean;

    startAdornment?: string;
    endAdornment?: string;
    closeIcon?: boolean;

    name?: string;

    nightTheme?: boolean;
}

export const Input: FC<InputProps> = ({
        value,
        type,
        label,
        error,
        onFocus,
        onChange,
        startAdornment,
        endAdornment,
        onBlur,
        inputWrapperClassName,
        inputLabelClassName,
        inputClassName,
        closeIcon,
        nightTheme,
        ...props
    }) => {

    const {
        inputWrapper,
        input,
        inputLabelClass,
        inputCloseIconClass,
        inputActiveClass,
        inputDisabledClass,
        inputErrorClass,
        inputLabelActiveClass,
        inputLabelErrorClass,
        inputEndAdornmentClass,
        inputWrapperDark,
        inputDark,
        inputActiveClassDark,
        inputLabelDark,
        inputStartAdornment
    } = useStyles();

    const inputEl = useRef({} as HTMLInputElement);

    const [activeClass, setActiveClass] = useState(false);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        if (disabled) return;

        setActiveClass(true);

        if (onFocus) {
            onFocus(e);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setActiveClass(false);

        if(onBlur) {
            onBlur(e);
        }
    }

    const handleClean = () => {
        const evt = new Event('change');
        const { current } = inputEl;

        current.value = '';

        const event = {
            ...evt,
            target: current,
            currentTarget: current
        }

        if (onChange) {
            onChange(event);
        }

        current.focus();
    }

    const disabled = false;

    const inputType =  type ? type : 'text';

    const combinedInputClass = `${input} ${disabled ? inputDisabledClass: ''} ${nightTheme ? inputDark : ''} ${inputClassName}`;

    const combinedInputWrapperClass = `${inputWrapper} ${inputWrapperClassName} ${disabled ? inputDisabledClass : ''} ${nightTheme ? inputWrapperDark : ''} ${activeClass ? nightTheme ? inputActiveClassDark : inputActiveClass : ''} ${error ? inputErrorClass : '' }`;

    const combinedInputLabelClass = `${inputLabelClass} ${inputLabelClassName} ${isValueSet(value, inputType) || activeClass ? inputLabelActiveClass : ''} ${error ? inputLabelErrorClass : '' } ${nightTheme ? inputLabelDark : ''}`;

    const isEndAdornmentShown = activeClass || error || value;

    return (
        <div className={combinedInputWrapperClass}>
            {startAdornment && (
                <p className={inputStartAdornment}>
                    {startAdornment}
                </p>
            )}

            {label && (
                <p className={combinedInputLabelClass}>
                    {label} {endAdornment && !isEndAdornmentShown ? `- ${endAdornment}` : ''}
                </p>
            )}
            <input
                ref={inputEl}
                type={inputType}
                disabled={disabled}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className={combinedInputClass}
                value={value}
                onChange={handleChange}
                name={props.name}
                min={0}
                {...props}
            />
            {endAdornment && isEndAdornmentShown && (
                <p className={inputEndAdornmentClass}>
                    {endAdornment}
                </p>
            )}
            {closeIcon && value && (
                <img className={inputCloseIconClass} onClick={handleClean} src={closeIconSvg} alt='Reset' />
            )}
        </div>
    );
}
