import React from 'react';
import { ReviewsIOWidget } from '@ruby-labs/ui-core-kit';

import { ABLE_STORE } from 'constants/able-store';

import { useStyles } from './styles';

export const ReviewsIO = () => {
    const { reviewsIOWrapper, wrapper, title } = useStyles();

    return (
        <div className={reviewsIOWrapper}>
            <div className={wrapper}>
                <h1 className={title}>
                    Able testimonials
                </h1>

                <ReviewsIOWidget store={ABLE_STORE} />
            </div>
        </div>
    );
};
