import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    content: {
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    title: {
        fontSize: '18px',
        lineHeight: '24px',
        marginTop: '0',
        marginBottom: '12px',
    },
    text: {
        fontSize: '16px',
        lineHeight: '24px',
        marginTop: '0',
        whiteSpace: 'pre-line',
    },
    '@media screen and (max-width: 320px)': { // iPhone 4/5 resolution
        text: {
            fontSize: '15px',
        }
    },
    '@media screen and (max-width: 280px)': { // Galaxy Fold resolution
        text: {
            fontSize: '13px',
        }
    }
});
