import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles ({
    choiceButtonWrapper: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '560px',
        gap: '12px',

        '& > div': {
            width: '100%',
        },

        '& > div > div button': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            minWidth: 'unset',

            '& img': {
                width: '100%',
                height: '122px'
            }
        }
    }
});
