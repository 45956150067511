import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    ratingWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        padding: '48px 18px 32px',
        background: '#F6F6F6',
    },
    applicationsWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '24px'
    },
    appRating: {
        width: '32px',
    },
    googleRating: {
        marginRight: '-8px'
    },
    rating: {},
    divider: {
        width: '144px',
        border: '1px solid #999999',
        margin: '24px 0',
    },

    journalsLogos: {
        width: '100%',
        maxWidth: '620px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    womenHealthIcon: {
        width: '120px',
        height: '23px',
    },
    forbesIcon: {
        width: '101px',
        height: '24px',
    },
    yahooIcon: {
        width: '84px',
        height: '24px',
    },
    '@media screen and (max-width: 360px)': { // Galaxy S8 / iPhone 4/5 resolution
        womenHealthIcon: {
            width: '90px',
        },
        forbesIcon: {
            width: '90px',
        },
        yahooIcon: {
            width: '60px',
        },
    },
    '@media screen and (max-width: 280px)': { // galaxy Fold
        journalsLogos: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
        },
    },
    '@media (min-width: 768px)': {
        ratingWrapper: {
            flexDirection: 'row',
            padding: '80px 18px 48px'
        },
        divider: {
            width: '0px',
            height: '36px',
            margin: '0px 34px',
        },
        googleRating: {
            marginRight: 0
        },
        appRating: {
            width: '36px',
        },
        womenHealthIcon: {
            width: 'unset',
            height: 'unset',
        },
        forbesIcon: {
            width: 'unset',
            height: 'unset',
        },
        yahooIcon: {
            width: 'unset',
            height: 'unset',
        }
    },
});
