import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    title: {
        lineHeight: '24px',
        textAlign: 'left',
        margin: '32px 0 16px',
    },
    '@media (min-width: 768px)' : {
        title: {
            margin: '40px 0 24px',
        }
    }
});
