import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserEligibilityKeys } from 'constants/user-eligibility';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getSelectedPageValues, isNextButtonDisabled } from 'helpers/multichoice-button-options';
import { isUserStatusNeedsReview } from 'helpers/get-user-eligibility';

export const MedicalConditionPart2 = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicalConditionPart2];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: liverProblemUserAnswer, setPageValue: setLiverProblemUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-liver-problem-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: kidneyProblemUserAnswer, setPageValue: setKidneyProblemUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-kidney-problem-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: diabetesUserAnswer, setPageValue: setDiabetesUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-diabetes-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: eatingDisordersUserAnswer, setPageValue: setEatingDisordersUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-eating-disorders-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: otherMedicationUserAnswer, setPageValue: setOtherMedicationUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-other-medication-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: medicalConditionWeightGainUserAnswer, setPageValue: setMedicalConditionWeightGainUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-medical-condition-weight-gain-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: seizuresUserAnswer, setPageValue: setSeizuresUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-seizures-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: depressionOrBipolarDisorderUserAnswer, setPageValue: setDepressionOrBipolarDisorderUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalConditionPart2]}-depression-or-bipolar-disorder-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'liver-problem',
            label: t('medical-condition-part-2-option-liver-problem'),
            userAnswer: liverProblemUserAnswer,
            setUserAnswer: setLiverProblemUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'kidney-problem',
            label: t('medical-condition-part-2-option-kidney-problem'),
            userAnswer: kidneyProblemUserAnswer,
            setUserAnswer: setKidneyProblemUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'diabetes',
            label: t('medical-condition-part-2-option-diabetes'),
            userAnswer: diabetesUserAnswer,
            setUserAnswer: setDiabetesUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'eating-disorders',
            label: t('medical-condition-part-2-option-eating-disorders'),
            userAnswer: eatingDisordersUserAnswer,
            setUserAnswer: setEatingDisordersUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'other-medication',
            label: t('medical-condition-part-2-option-other-medication'),
            userAnswer: otherMedicationUserAnswer,
            setUserAnswer: setOtherMedicationUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'medical-condition-weight-gain',
            label: t('medical-condition-part-2-option-medical-condition-weight-gain'),
            userAnswer: medicalConditionWeightGainUserAnswer,
            setUserAnswer: setMedicalConditionWeightGainUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'seizures',
            label: t('medical-condition-part-2-option-seizures'),
            userAnswer: seizuresUserAnswer,
            setUserAnswer: setSeizuresUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'depression-or-bipolar-disorder',
            label: t('medical-condition-part-2-option-depression-or-bipolar-disorder'),
            userAnswer: depressionOrBipolarDisorderUserAnswer,
            setUserAnswer: setDepressionOrBipolarDisorderUserAnswer,
            placeholder: t('medical-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'none',
            label: t('medical-condition-part-2-option-none'),
        }
    ];

    const selectedPageValues = getSelectedPageValues(pageValue);

    const handleClick = () => {
        const selectOption = selectedPageValues.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.value === item);

            return `${currentOption?.label}: ${currentOption?.userAnswer?.trim()}`;
        });

        let userStatus = selectedPageValues[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicalConditionPageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalCondition]) as string);
        const medicationIntakePageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntake]) as string);
        const medicationIntakePart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntakePart2]) as string);

        if (
            isUserStatusNeedsReview(medicalConditionPageValue, userStatus) ||
            isUserStatusNeedsReview(medicationIntakePageValue, userStatus) ||
            isUserStatusNeedsReview(medicationIntakePart2PageValue, userStatus)
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('medical-condition-part-2-title'), value: selectOption },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus }
        });

        pageConfiguration.handleOnPageComplete(!selectedPageValues.includes('none') ? {
            pageValue,
            selectOption
        } : { pageValue });
    };

    return (
        <>
            <Heading title={t('medical-condition-part-2-title')} subtitle={t('medical-condition-part-2-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick}
                            disabled={isNextButtonDisabled(selectedPageValues, multiChoices)} />
            </FloatedButton>
        </>
    );
};
