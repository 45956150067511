import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    accountFormDetailsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        background: '#F6F6F6',
        borderRadius: '8px',
        padding: '16px',
    },
    blockTitle: {
        margin: 'unset',
        lineHeight: '26px',
        textAlign: 'left',
        fontSize: '18px',
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    info: {
        margin: 'unset',
        lineHeight: '18px',
        textAlign: 'left',
        fontSize: '14px',
    },
    button: {
        background: 'none',
        border: 'none',
        float: 'right',
    },
    link: {
        textDecorationLine: 'underline',
        color: '#1375D6',
        cursor: 'pointer'
    }
});
