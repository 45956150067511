import { InputType, InputStyles } from './default/input';
import { ButtonType, ButtonStyles } from './default/button';
import { DropdownType, DropdownStyles } from './default/dropdown';
import { NextButtonStyles, NextButtonType } from './default/next-button';

import { HeaderStyles, HeaderType } from './default/header';
import { ProgressBarStyles, ProgressBarType } from './default/progress-bar';
import { BackArrowButtonStyles, BackArrowButtonType } from './default/back-arrow-button';

export type Palette = Record<string, string>

export type ThemeProps = {
    input: InputType,
    button: ButtonType,
    dropdown: DropdownType,
    nextButton: NextButtonType,
    backArrowButton: BackArrowButtonType,
    header: HeaderType,
    progressBar: ProgressBarType
}

export type Theme = {
    theme: ThemeProps,
    overwrite?: Partial<ThemeProps>,
}

export const defaultTheme: ThemeProps = {
    input: InputStyles,
    button: ButtonStyles,
    dropdown: DropdownStyles,
    nextButton: NextButtonStyles,
    backArrowButton: BackArrowButtonStyles,
    header: HeaderStyles,
    progressBar: ProgressBarStyles,
};
