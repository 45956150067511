import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, getSelectedPageValues } from 'helpers/multichoice-button-options';

export const HealthCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.HealthCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'pregnant',
            label: t('health-condition-option-pregnant')
        },
        {
            value: 'breastfeeding',
            label: t('health-condition-option-breastfeeding')
        },
        {
            value: 'cancer',
            label: t('health-condition-option-cancer')
        },
        {
            value: 'eating-disorder',
            label: t('health-condition-option-eating-disorder')
        },
        {
            value: 'substance-dependency',
            label: t('health-condition-option-substance-dependency')
        },
        {
            value: 'diabetes',
            label: t('health-condition-option-diabetes')
        },
        {
            value: 'bariatric-surgery',
            label: t('health-condition-option-bariatric-surgery')
        },
        {
            value: 'pancreatitis',
            label: t('health-condition-option-pancreatitis'),
        },
        {
            value: 'thyroid-cancer',
            label: t('health-condition-option-thyroid-cancer'),
        },
        {
            value: 'none',
            label: t('health-condition-option-none'),
        }
    ];

    const multiChoicesByGender: IMultiChoiceButtonOption[] = userGender === 'male' ? multiChoices.slice(2) : multiChoices;

    const handleClick = () => {
        const selectedPageValues = getSelectedPageValues(pageValue);
        const selectOption = getMultiChoiceButtonSelectOptions(selectedPageValues, multiChoicesByGender);

        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('health-condition-title'),
                value: pageValue[0].value === 'none'
                    ?
                    multiChoicesByGender
                        .filter(multiChoice => multiChoice.value !== 'none')
                        .map(multiChoice => `"${multiChoice.label}" - NO`)
                    :
                    selectOption
            },
        });

        pageConfiguration.handleOnPageComplete(selectedPageValues);
    };

    return (
        <>
            <Heading title={t('health-condition-title')} subtitle={t('health-condition-subtitle')} />

            <MultiChoice
                options={multiChoicesByGender}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
