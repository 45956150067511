import { AnalyticsService } from './analytics.service';

import { RoutePath } from 'routes/route-path.constant';

import { ABPagesConfiguration } from 'services/ab-pages-configuration.service';

export class PageConfigurationService extends AnalyticsService {

    // @ts-ignore
    private page: PageType;

    private navigate: any = {};

    private onPageComplete = (data: {} | string) => {

        if (this.page.events.onLeave) {
            const name = this.page.events.onLeave(data);
            if (name) {
                this.event(name, data);
            }
        }

        setTimeout(() => {
            const page = this.page.route.next(data);
            return this.navigate(`..${page}`);
        }, 100);
    };

    setNavigate(navigate: any) {
        this.navigate = navigate;
        return this;
    }

    getPageConfiguration() {
        return this.page;
    }

    handleOnPageEnter() {
        if (this.page.events.onEnter) {
            this.event(this.page.events.onEnter());
        }

        return this;
    }

    handleOnPageComplete(data?: {} | string) {
        let params = data ? JSON.parse(JSON.stringify(data)) : '';

        return this.onPageComplete(params);
    }

    setConfigurationByLocation(location: any) {
        const pagesConfiguration = ABPagesConfiguration();

        let page = pagesConfiguration[location.pathname];

        if (!page) {
            console.error(`Failed to take page configuration for location.pathname = ${location.pathname}`);
            page = pagesConfiguration[RoutePath.MainPage];
        }

        this.page = page;

        return this;
    }
}
