import i18n from '../../../translations/i18n';

export const kgValidationQuery: any = {
    type: {
        value: 'number',
        moreThen: {
            value: 26.76,
            errorMessage: i18n.t('weight-validation-error-message'),
        },
        lessThen: {
            value: 454.046,
            errorMessage: i18n.t('weight-validation-error-message'),
        },
    },
};
