import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    tipBannerText: {
        lineHeight: '16px',

        '& span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
        }
    },
    '@media only screen and (min-width: 768px)' : {
        tipBannerText: {
            lineHeight: '18px',

            '& span': {
                fontSize: '16px',
            }
        }
    }
});
