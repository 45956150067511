import React from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

type CheckPointsListProps = {
    items: string[];
    listItemClassName?: string;
    listItemTextClassName?: string;
    listItemTextComponents?: JSX.Element[];
}

export const CheckPointsList = ({ items, listItemClassName, listItemTextClassName, listItemTextComponents }: CheckPointsListProps): JSX.Element => {
    const pageImages = useBackgroundImageService(['svg/check-icon.svg']);

    return (
        <>
            {
                items.map((item, index) => {
                    return (
                        <div className={listItemClassName} key={index}>
                            <img src={pageImages[0]} alt='Check Icon' />
                            <p className={listItemTextClassName}>
                                <Trans i18nKey={item} components={listItemTextComponents} />
                            </p>
                        </div>
                    );
                })
            }
        </>
    );
};
