import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    marginTop: {
        marginTop: '16px'
    },
    thanksForSharingTextWrapper: {
        margin: '0 0 12px',

        '@media (min-width: 768px)' : {
            margin: '0 0 16px'
        }
    },
    thanksForSharingText: {
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'left',

        '&:first-of-type': {
            color: '#268266',
            fontWeight: 600,
            margin: '16px 0 0',
        }
    },
    excitementText: {
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'left',
        margin: '0 0 12px',

        '&:first-of-type': {
            color: '#268266',
            fontWeight: 600,
            margin: '16px 0 0',
        }
    }
});
