export type PlanType = {
    title: string,
    key: string,
    amount: number,
    subscriptionAmount: number,
    trialPeriod: number,
    subscriptionPeriod: number,
    chargePeriodUnit: string,
    currencyCode: string,
    productId: number | null,
    bundleId: number | null,
}

export const PLAN: PlanType = {
    title: 'Able RX - Weight Loss',
    key: 'ablerxuk_v1_19900_month_USD',
    amount: 0,
    subscriptionAmount: 19900,
    trialPeriod: 0,
    subscriptionPeriod: 30, /* chargePeriod - numbers of days or month, answer for - How often user needs to be billed (X Days) */
    chargePeriodUnit: 'day', /* period (X Days) */
    currencyCode: 'USD',
    productId: null,
    bundleId: 1,
};
