import React, { useContext, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Heading } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { UsersApiProvider } from 'api/users.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserVerificationKeys } from 'constants/user-verification';

import { NextButton } from 'components/next-button';
import { ValidatedInput } from 'components/validated-input';

import { useStyles } from './styles';

export const Email = () => {
    const { t } = useTranslation();

    const { agreementText } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const handleClick = () => {
        UsersApiProvider
            .isUserActive(email)
            .then(({ isUserActive }) => {
                localStorage.setItem(UserVerificationKeys.isUserActive, String(isUserActive));
                pageConfiguration.handleOnPageComplete({ email, isUserActive });
            });
    };

    const [pageError, setPageError] = useState(false);

    const onChange = (e: any) => {
        setEmail(e.target.value);
    };

    const onError = (error: boolean) => {
        setPageError(error);
    };

    return (
        <>
            <Heading title={t('email-title')} />

            <ValidatedInput
                value={email}
                onChange={onChange}
                onError={onError}
                validationQuery={{
                    type: {
                        value: 'email',
                        errorMessage: t('email-input-error-message'),
                    },
                }}
                label={t('email-input-label')}
            />

            <p className={agreementText}>
                <Trans i18nKey='email-text' />
            </p>

            <NextButton
                onClick={handleClick}
                typographyText={t('email-button-next')}
                disabled={!email || pageError}
            />
        </>
    );
};
