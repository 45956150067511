import React, { memo } from "react";

interface ListItemProps {
    id: string | number;
    isOpen: boolean;
    TitleComponent: React.ElementType;
    DetailComponent: React.ElementType;
    itemWrapperClass?: string;
}

const ListItem = ({
      id,
      isOpen,
      TitleComponent,
      DetailComponent,
      itemWrapperClass,
      ...rest
    }: ListItemProps) => {
        return (
            <li
                role="button"
                tabIndex={0}
                aria-expanded={isOpen}
                aria-controls={`acc-content-${id}`}
                id={id.toString()}
                className={itemWrapperClass}>
                <TitleComponent {...rest} isOpen={isOpen} />
                {isOpen && (
                    <div role="definition" className="acc-content" id={`acc-content-${id}`}>
                        <DetailComponent {...rest} isOpen={isOpen} />
                    </div>
                )}
            </li>
        );
};

export default memo(ListItem);
