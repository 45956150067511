import React, { ChangeEvent, FC, useState, KeyboardEvent, useRef, useEffect } from 'react';

import { Input, InputProps } from "../input";

import { debounce } from 'helpers';

import { validator, numberValidator, ValidationQueryType, ValidationTypeProps } from "./validator";

import { useStyles } from "./styles";

const NUMBER_TYPE = 'number';
const PHONE_VALIDATION_TYPE = 'phone';

const numberKeys = {
    lessThen: true,
    moreThen: true,
    equal: true
};

export interface ValidatedInputProps extends InputProps {
    toggleValidationListener?: any,
    onError?: (invalid: boolean) => void,
    validationQuery?: {
        minLength?: ValidationQueryType,
        maxLength?: ValidationQueryType,
        type?: ValidationTypeProps
    },
    onKeyDown?: (e: KeyboardEvent) => void,
    onBlur?: (e: any) => void,
    errorDelayTime?: number,
    value: string,
    maxLength?: number | string,
    wrapperClass?: string,
    nightTheme?: boolean
}

export const ValidatedInput: FC<ValidatedInputProps> = ({
    toggleValidationListener,
    onError,
    onChange,
    validationQuery = { type: 'emptyString' },
    errorDelayTime = 0,
    onBlur,
    wrapperClass = '',
    ...otherProps
}) => {

    const touched = useRef<any>(Boolean(otherProps.value));
    const { validatedInputWrapper, inputErrorText } = useStyles();
    const [errors, setErrors] = useState<any>([]);
    const { type } = validationQuery;

    // @ts-ignore
    const isNumberType = Boolean(type?.value === NUMBER_TYPE);
    // @ts-ignore
    const isPhoneType = Boolean(type?.value === PHONE_VALIDATION_TYPE);

    const toggleError = (errors: any) => {
        if (onError) onError(Boolean(errors.length));

        setErrors(errors);
    };

    const { current: delayedToggleError } = useRef(debounce(toggleError, errorDelayTime));

    const validateInput = (value: string) => {
        let errors = [];

        if (isNumberType) {
            // @ts-ignore
            errors = Object.keys(type).reduce((err, key) => {
                // @ts-ignore
                const isCorrectKey = Boolean(numberKeys[key]);

                if(isCorrectKey) {
                    // @ts-ignore
                    const param = type[key];
                    // @ts-ignore
                    const error = numberValidator[key](Number(value), param)
                    // @ts-ignore
                    if(error) err.push(param)
                }

                return err;
            }, []);

            delayedToggleError(errors);

        }  else {
            errors = Object.keys(validationQuery).reduce((err, key) => {
                // @ts-ignore
                const paramValidator = validator[key];

                if (paramValidator) {
                    // @ts-ignore
                    const param = validationQuery[key];
                    // @ts-ignore
                    const error = paramValidator(value, param);
                    // @ts-ignore
                    if (error) err.push(param);
                }

                return err;
            }, []);
        }

        delayedToggleError(errors);
    }

    const handleBlur = (e: any) => {
        if(!touched.current) {
            touched.current = true;

            const { value = '' } = e.target;

            if (value) {
                validateInput(value);
            }
        }

        if(onBlur) {
            onBlur(e);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement> | Event) => {
        if(!touched.current) {
            touched.current = true
        }

        // @ts-ignore
        const { value } = e.target;

        if (value && (isNumberType || isPhoneType)) {
            const regexp = /^\d+$/;

            const isNotNumber = !regexp.test(value);
            if (isNotNumber) {
                e.preventDefault();
                return;
            }
        }

        validateInput(value);

        if(onChange) onChange(e);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        const { onKeyDown } = otherProps;

        if (onKeyDown) onKeyDown(e);

        if (!isNumberType && !isPhoneType) return;

        const regexp = /[0-9]/;
        const isBackspace = e.key === 'Backspace';
        const isNotNumber = !regexp.test(e.key) && !isBackspace;

        if (isNotNumber) e.preventDefault();
    };

    useEffect(() => {
        if (touched.current || toggleValidationListener) {
            const { value = '' } = otherProps;
            validateInput(value);
        }
    }, [toggleValidationListener, JSON.stringify(otherProps)]);

    const [isErrorMessageExist, setIsErrorMessageExist] = useState(false);

    useEffect(() => {
        if (errors?.length && errors[0].errorMessage && (errors[0].value === 'emptyString' || otherProps.value || toggleValidationListener)) {
            setIsErrorMessageExist(true);
        } else {
            setIsErrorMessageExist(false);
        }
    }, [JSON.stringify(errors), JSON.stringify(otherProps), toggleValidationListener]);

    return (
        <div className={`${validatedInputWrapper} ${wrapperClass}`}>
            <Input
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                error={isErrorMessageExist}
                {...(isNumberType ? { pattern: "[0-9]*" }: {})}
                {...otherProps}
            />
            {isErrorMessageExist && errors?.length &&
                <p className={inputErrorText}>
                    {errors?.length ? errors[0].errorMessage : 'Wrong input value'}
                </p>
            }
        </div>
    )
}
