import React from 'react';

import errorIcon from './error_icon.svg';

import { useStyles } from './styles';

type ErrorBlockProps = {
    error: string,
}

export const ErrorBlock = ({ error }: ErrorBlockProps) => {
    const { errorWrapper, errorTypography } = useStyles();

    return (
        <div className={errorWrapper}>
            <img src={errorIcon} alt="icon"/>
            <p className={errorTypography}>
                {error}
            </p>
        </div>
    )
};
