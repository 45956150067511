// Graphics
import weightFactorsIllustration from 'assets/images/svg/weight-factors/weight-factors-illustration.svg';

// Body gender images
import maleGender from 'assets/images/svg/body-gender/male-gender.svg';
import femaleGender from 'assets/images/svg/body-gender/female-gender.svg';

// Icons
import checkIcon from 'assets/images/svg/check-icon.svg';

// Decline user
import noMedication from 'assets/images/svg/decline-user/no-medication.svg';

// Checkout
import shippingIcon from 'assets/images/svg/checkout/order-details/shipping-icon.svg';
import cancelIcon from 'assets/images/svg/checkout/order-details/cancel-icon.svg';
import medicalAdvisorIcon from 'assets/images/svg/checkout/order-details/medical-advisor-icon.svg';
import cartIcon from 'assets/images/svg/checkout/cart/cart-icon.svg';
import cartMedications from 'assets/images/webp/checkout/cart/medications.webp';
import navigationIcon from 'assets/images/svg/checkout/breadcrumbs-navigation/navigation-icon.svg';

// Medication
import calendar from 'assets/images/svg/medication/calendar.svg';
import refund from 'assets/images/svg/medication/refund.svg';
import medicationBottle from 'assets/images/svg/medication/medication-bottle.svg';
import delivery from 'assets/images/svg/medication/delivery.svg';
import app from 'assets/images/svg/medication/rating/app.svg';
import google from 'assets/images/svg/medication/rating/google.svg';
import rating from 'assets/images/svg/medication/rating/rating.svg';
import forbes from 'assets/images/svg/medication/rating/forbes.svg';
import yahoo from 'assets/images/svg/medication/rating/yahoo.svg';
import womenHealth from 'assets/images/svg/medication/rating/women-health.svg';
import book from 'assets/images/svg/medication/medication-description/book.svg';
import tryImage from 'assets/images/svg/medication/medication-description/try.svg';
import person from 'assets/images/svg/medication/medication-description/person.svg';
import clock from 'assets/images/svg/medication/medication-description/clock.svg';
import phone from 'assets/images/svg/medication/phone.svg';
import medicationsPack from 'assets/images/webp/medication/medications-pack.webp';
import medication from 'assets/images/webp/medication/medication-description/medication.webp';
import play from 'assets/images/svg/medication/video-player/play.svg';
import cross from 'assets/images/svg/medication/video-player/cross.svg';
import prevButton from 'assets/images/svg/medication/video-customer-reviews/prev-button.svg';
import nextButton from 'assets/images/svg/medication/video-customer-reviews/next-button.svg';

import doctorPhoto from 'assets/images/webp/medication/medical-director.webp';
import coachMessage from 'assets/images/svg/medication/coach-message.webp';

export const BackgroundLoadingImages = () => {
    return {
        images: [
            { key:'svg/body-gender/male-gender.svg', pathUrl: maleGender },
            { key:'svg/body-gender/female-gender.svg', pathUrl: femaleGender },
            { key:'svg/weight-factors/weight-factors-illustration.svg', pathUrl: weightFactorsIllustration },
            { key:'svg/decline-user/no-medication.svg', pathUrl: noMedication },
            { key:'svg/check-icon.svg', pathUrl: checkIcon },
            { key:'svg/checkout/order-details/shipping-icon.svg', pathUrl: shippingIcon },
            { key:'svg/checkout/order-details/cancel-icon.svg', pathUrl: cancelIcon },
            { key:'svg/checkout/order-details/medical-advisor-icon.svg', pathUrl: medicalAdvisorIcon },
            { key:'svg/checkout/cart/cart-icon.svg', pathUrl: cartIcon },
            { key:'webp/checkout/cart/medications.webp', pathUrl: cartMedications },
            { key:'svg/checkout/breadcrumbs-navigation/navigation-icon.svg', pathUrl: navigationIcon },
            { key:'svg/medication/calendar.svg', pathUrl: calendar },
            { key:'svg/medication/refund.svg', pathUrl: refund },
            { key:'svg/medication/medication-bottle.svg', pathUrl: medicationBottle },
            { key:'svg/medication/delivery.svg', pathUrl: delivery },
            { key:'svg/medication/rating/app.svg', pathUrl: app },
            { key:'svg/medication/rating/google.svg', pathUrl: google },
            { key:'svg/medication/rating/rating.svg', pathUrl: rating },
            { key:'svg/medication/rating/forbes.svg', pathUrl: forbes },
            { key:'svg/medication/rating/yahoo.svg', pathUrl: yahoo },
            { key:'svg/medication/rating/women-health.svg', pathUrl: womenHealth },
            { key:'svg/medication/medication-description/medication.svg', pathUrl: medication },
            { key:'svg/medication/video-player/play.svg', pathUrl: play },
            { key:'svg/medication/video-player/cross.svg', pathUrl: cross },
            { key:'svg/medication/video-customer-reviews/next-button.svg', pathUrl: nextButton },
            { key:'svg/medication/video-customer-reviews/prev-button.svg', pathUrl: prevButton },
            { key:'svg/medication/medication-description/book.svg', pathUrl: book },
            { key:'svg/medication/medication-description/try.svg', pathUrl: tryImage },
            { key:'svg/medication/medication-description/person.svg', pathUrl: person },
            { key:'svg/medication/medication-description/clock.svg', pathUrl: clock },
            { key:'svg/medication/medication-description/phone.svg', pathUrl: phone },
            { key:'webp/medication/medications-pack.webp', pathUrl: medicationsPack },
            { key:'webp/medication/medication-description/medication.webp', pathUrl: medication },
            { key:'webp/medication/medical-director.webp', pathUrl: doctorPhoto },
            { key:'svg/medication/coach-message.webp', pathUrl: coachMessage },
        ]
    }
};
