import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { Primer, PaymentFlow } from '@primer-io/checkout-web';

import { PrimerProvider } from 'api/primer.api';

import { isNumber } from 'helpers';
import { getSignUpData } from 'helpers/sign-up-data';

import { PLAN } from 'constants/plan';

import { useStyles } from './styles';

type PrimerIOPaywallType = {
    email: string,
    amount: number,
    currencyCode: string,
    onError: any,
    onBeforePaymentCreate: any,
    onPaymentCreationStart: any,
    onCheckoutComplete: any,
    onCheckoutFail: any,
    translation: {
        generalError?: string,
        creditCardForm: {
            input: {
                namePlaceholder: string,
                cardNumberPlaceholder: string,
                cardExpiryPlaceholder: string,
                cardCvvPlaceholder: string,
            },
            submitButtonText: string
        }
    },
    setShowUserExistsMessagePrimer: React.Dispatch<React.SetStateAction<boolean>>;
};

let UniversalCheckout: any = null;

export const PrimerIOPaywall = (
    { email, amount, currencyCode, onError, onBeforePaymentCreate, onPaymentCreationStart, onCheckoutComplete, onCheckoutFail, translation, setShowUserExistsMessagePrimer }: PrimerIOPaywallType
) => {
    const { submitButtonWrapper, continueButton, buttonVisible } = useStyles();

    const [clientToken, setClientToken] = useState('');
    const [userId, setUserId] = useState('');
    const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);

    useEffect(() => {
        if (email && isNumber(amount) && currencyCode) {

            const plan = { ...PLAN };

            const data = getSignUpData(
                {
                    planCode: plan.key,
                    amount: plan.subscriptionAmount,
                    subscriptionAmount: plan.subscriptionAmount,
                    trialPeriodDays: plan.trialPeriod,
                    chargePeriod: plan.subscriptionPeriod,
                    chargePeriodUnit: plan.chargePeriodUnit,
                    currencyCode: plan.currencyCode,
                    nextBillingDate: dayjs().add(plan.subscriptionPeriod, plan.chargePeriodUnit as any).toISOString(),

                    // Fields for combined subscription (app & medical)
                    combined: 'true',
                    productId: plan.productId,
                    bundleId: plan.bundleId,
                    type: 'Primer',
                }
            );

            PrimerProvider.getClientToken(data).then((data) => {
                setClientToken(data.clientToken);
                setUserId(data.userId);
            }).catch(error => {
                    console.log('getClientToken - error', error);
                    setShowUserExistsMessagePrimer(true);
                }
            );
        }
    }, [email, amount, currencyCode]);

    const handleMySubmitButtonClick = () => {
        if (UniversalCheckout) {
            UniversalCheckout.then((data: any) => data.submit());
        }
    };

    useEffect(() => {
            if (clientToken) {
                UniversalCheckout = Primer.showUniversalCheckout(clientToken, {
                    container: '#primerio-container',
                    vault: {
                        visible: false,
                    },
                    successScreen: false,
                    form: {
                        inputLabelsVisible: false,
                    },
                    submitButton: {
                        useBuiltInButton: false, // Hide the built-in submit button
                        onVisible: (isVisible: boolean) => {
                            setIsSubmitButtonVisible(isVisible)
                        },
                    },
                    style: {
                        formSpacings: {
                            betweenLabelAndInput: '0px',
                            betweenInputs: '12px',
                        },
                        input: {
                            base: {
                                fontFamily: 'SF Pro Text, system-ui, sans-serif',
                                height: '56px',
                                borderRadius: '0.5rem',
                                borderStyle: 'none none solid none',
                                borderColor: '#FFFFFF',
                                borderWidth: '2px',
                                focus: {
                                    background: '#F6F6F6',
                                    borderColor: '#1375D6',
                                    borderWidth: '2px',
                                },
                                placeholder: {
                                    color: '#868C99',
                                },
                            },
                            error: {
                                background: '#F6F6F6',
                                borderStyle: 'none none solid none',
                                borderWidth: '2px',
                                focus: {
                                    background: '#F6F6F6'
                                },
                            }
                        },
                        inputErrorText: {
                            fontFamily: 'SF Pro Text, system-ui, sans-serif',
                            fontSize: '12px',
                            color: '#FF4D2B',
                            fontWeight: '400',
                            lineHeight: '16px',
                        },
                        submitButton: {
                            base: {
                                color: '#ffffff',
                                background: '#1375D6',
                                borderRadius: '8px',
                                fontFamily: 'SF Pro Text, system-ui, sans-serif',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                fontSize: '18px',
                            },
                            loading: {
                                color: '#ffffff',
                                background: '#1375D6',
                            },
                            disabled: {
                                color: '#ffffff',
                                background: '#1375D6',
                            }
                        },
                        focusCheckoutOnInit: false
                    },
                    paypal: {
                        paymentFlow: PaymentFlow.PREFER_VAULT
                    },
                    errorMessage: {
                        // Disable the appearance of the
                        //default error message
                        // Default to false
                        disabled: true,

                        onErrorMessageShow(message: string) {
                            onError(message);
                            onPaymentCreationStart(false);
                        },

                        onErrorMessageHide() {
                            onError('');
                        },
                    },
                    card: {
                        cardholderName: {
                            placeholder: translation.creditCardForm.input.namePlaceholder,
                        },
                        cvv: {
                            placeholder: translation.creditCardForm.input.cardCvvPlaceholder,
                        },
                        cardNumber: {
                            placeholder: translation.creditCardForm.input.cardNumberPlaceholder,
                        }
                    },
                    async onBeforePaymentCreate({ paymentMethodType }, handler) {
                        onBeforePaymentCreate(paymentMethodType);
                        handler.continuePaymentCreation();
                    },
                    async onPaymentCreationStart() {
                        onPaymentCreationStart(true);
                    },
                    async onCheckoutComplete({ payment }) {
                        onCheckoutComplete({ ...payment, userId });
                    },
                    async onCheckoutFail(error, { payment }, handler) {
                        onCheckoutFail(error, payment);
                        handler?.showErrorMessage(translation.generalError);
                        await PrimerProvider.throwErrorToWebhook('[PRIMER] [onCheckoutFail]', error);
                    }
                });
            }

            return () => {
                if (UniversalCheckout) {
                    UniversalCheckout.then((data: any) => data.teardown());
                }
            }
        },
        [clientToken]);

    return (
        <>
            <div id='primerio-container'></div>

            <div className={submitButtonWrapper}>
                <button type='submit' className={`${continueButton} ${isSubmitButtonVisible && buttonVisible}`} onClick={handleMySubmitButtonClick}>
                    {translation.creditCardForm.submitButtonText}
                </button>
            </div>
        </>
    );
};
