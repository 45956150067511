import { SlackWorkflowAPI } from '@ruby-labs/ui-core-kit';

class Primer extends SlackWorkflowAPI {

    constructor() {
        super({
            webHookUrl: process.env.REACT_APP_SLACK_WEBHOOK_PAYMENTS_MONITORING_URL as string
        });
    }

    getClientToken(data: any): Promise<{ clientToken: string, userId: string, clientTokenExpirationDate: string, metadata: any, order: any, paymentMethod: any }> {
        return fetch(`${process.env.REACT_APP_ABLE_API}/payment/getClientToken`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {
            return response.data;
        }).catch((e) => {
            console.log('[getClientToken request]', e);
            this.throwErrorToWebhook('[PRIMER] [getClientToken]', e);
            return Promise.reject(e);
        });
    }

    subscriptionCreate(data: any): Promise<{ customer: { id: string } }> {
        return fetch(`${process.env.REACT_APP_ABLE_API}/subscription/primer/create`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {
            if (response.cookie) {
                document.cookie = response.cookie;
            }

            return response.data;
        }).catch((e) => {
            console.log('[subscriptionCreate request]', e, data);
            this.throwErrorToWebhook('[PRIMER] [subscriptionCreate]', e);
            return Promise.reject(e);
        });
    }

    throwErrorToWebhook(method: string, error: any) {
        return this.sendMessageToWebhook({
            url: window.location.host,
            method,
            error: JSON.stringify(error)
        });
    }
}

export const PrimerProvider = new Primer();
