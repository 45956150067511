import i18n from 'i18next';
import dayjs from 'dayjs';

import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANG = 'en';

export const DEFAULT_VARIABLES = {
    ableRXUK: 'Able RX UK',
};

export const listOfTranslations = {
    'en': { translation: require('./en/en.json') },
};

const params = new URLSearchParams(document.location.search);
const language = params.get('language');

i18n.use(initReactI18next).init({
    supportedLngs: ['en'],
    returnObjects: true,
    fallbackLng: DEFAULT_LANG,
    lng: language || window.navigator.language || window.navigator.userLanguage,
    resources: {
        ...listOfTranslations,
    },
    interpolation: {
        defaultVariables: {
            ...DEFAULT_VARIABLES,
            escapeValue: false,
        },
    },
    react: {
        transWrapTextNodes: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'em', 's', 'b'],
    },
    debug: false,
    returnEmptyString: false
});

require(`dayjs/locale/en.js`);

dayjs.locale(i18n.resolvedLanguage);

export default i18n;
