import { Palette } from '../index';

export type NextButtonType = {
    nextButton: {
        fontFamily?: string,
        background: Palette | string,
        color: Palette | string,
        margin?: string
    } & { [key: string]: {} },
    nextButtonHovered: {
        background: Palette | string,
        color?: Palette | string,
    },
    nextButtonFocus: {
        background: Palette | string,
        color?: Palette | string,
        boxShadow?: string,
    },
    nextButtonDisabled: {
        cursor: string,
        pointerEvents: string,
        background: Palette | string,
        color: Palette | string,
    }
}

export const NextButtonStyles: NextButtonType = {
    nextButton: {
        background: '#EB731A',
        color: '#FFFFFF',
        margin: '32px 0',

        '@media only screen and (min-width: 768px)' : {
            margin: '40px 0'
        }
    },
    nextButtonHovered: {
        background: '#CA5010',
    },
    nextButtonFocus: {
        background: '#CA5010',
    },
    nextButtonDisabled: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        background: '#C5C5D1',
        color: '#FFFFFF',
    }
};
