import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'helpers';

import { ValidatedInput } from 'components/validated-input';

import { GoogleStreetAddressAutoComplete } from './google-street-address-auto-complete';

import { useStyles } from './styles';

export interface GoogleStreetAddress {
    result: {
        address_components: { long_name: string, short_name: string, types: string[] }[],
        formatted_address: string,
        place_id: string,
    },
    name: string,
    googleValue: string,
}

interface ShippingAddressProps {
    streetAddress: string;
    handleStreetAddressChange: (address: string) => void;
    handleSelectStreetAddress: ({ result, name, googleValue }: GoogleStreetAddress) => void;
    address2: string;
    onAddress2Change: (e: any) => void;
    city: string;
    onCityChange: (e: any) => void;
    county: string;
    onCountyChange: (e: any) => void;
    postCode: string;
    onPostCodeChange: (e: any) => void;
    validationListener: boolean;
    nightTheme?: boolean;

    streetAddressInvalid?: null | boolean;
    address2Invalid?: null | boolean;
    cityInvalid?: null | boolean;
    countyInvalid?: null | boolean;
    postCodeInvalid?: null | boolean;
}

export const ShippingAddress: FC<ShippingAddressProps> = ({
      streetAddress,
      streetAddressInvalid,
      validationListener,
      handleStreetAddressChange,
      handleSelectStreetAddress,
      address2,
      onAddress2Change,
      address2Invalid,
      city,
      onCityChange,
      cityInvalid,
      county,
      onCountyChange,
      countyInvalid,
      postCode,
      onPostCodeChange,
      postCodeInvalid,
      nightTheme
  }) => {
    const { t } = useTranslation();

    const { shippingAddressFormWrapper, multipleFieldsWrapper, error } = useStyles();

    const [generalError, setGeneralError] = useState(false);

    useEffect(() => {
        const isMoreThanOne = [streetAddressInvalid, address2Invalid, cityInvalid, countyInvalid, postCodeInvalid].filter(v => v === true)?.length > 1;
        if (isMoreThanOne) {
            setGeneralError(true);
        } else {
            setGeneralError(false);
        }
    }, [streetAddressInvalid, address2Invalid, cityInvalid, countyInvalid, postCodeInvalid]);

    const handleKeyDown = (e: KeyboardEvent) => {
        const regexp = /^[A-Za-z0-9\s-]*$/;
        const isBackspace = e.key === 'Backspace';
        const isNotValid = !regexp.test(e.key) && !isBackspace;

        if (isNotValid) e.preventDefault();
    };

    return (
        <div className={shippingAddressFormWrapper}>

            <div>
                <GoogleStreetAddressAutoComplete
                    value={streetAddress}
                    onChange={handleStreetAddressChange}
                    isError={validationListener && isEmpty(streetAddress)}
                    streetAddressInvalid={streetAddressInvalid}
                    handleSelectStreetAddress={handleSelectStreetAddress}
                    nightTheme={nightTheme} />
            </div>

            <div>
                <ValidatedInput
                    type='text'
                    value={address2}
                    onChange={onAddress2Change}
                    error={Boolean(address2Invalid)}
                    validationQuery={{ type: { value: 'emptyString' } }} // @TODO: Fix ValidatedInput components to not pass the unused stuff
                    toggleValidationListener={validationListener}
                    label={t('checkout-shipping-address-form-input-address-2')}
                    nightTheme={nightTheme} />
            </div>

            <div>
                <ValidatedInput
                    type='text'
                    value={city}
                    onChange={onCityChange}
                    error={Boolean(cityInvalid)}
                    validationQuery={{
                        type: {
                            value: 'emptyString',
                            errorMessage: t('checkout-input-error-empty-field'),
                        },
                    }}
                    toggleValidationListener={validationListener}
                    label={t('checkout-shipping-address-form-input-city')}
                    nightTheme={nightTheme} />
            </div>

            <div className={multipleFieldsWrapper}>
                <div>
                    <ValidatedInput
                        type='text'
                        value={county}
                        onChange={onCountyChange}
                        error={Boolean(countyInvalid)}
                        validationQuery={{
                            type: {
                                value: 'emptyString',
                                errorMessage: t('checkout-input-error-empty-field'),
                            },
                        }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-shipping-address-form-input-county')}
                        nightTheme={nightTheme} />
                </div>

                <div>
                    <ValidatedInput
                        type='text'
                        value={postCode}
                        onChange={onPostCodeChange}
                        error={Boolean(postCodeInvalid)}
                        onKeyDown={handleKeyDown}
                        validationQuery={{
                            type: {
                                value: 'pattern',
                                pattern: /^[A-Za-z0-9\s-]*$/,
                                errorMessage: 'Invalid field',
                            },
                        }}
                        toggleValidationListener={validationListener}
                        label={t('checkout-shipping-address-form-input-postcode')}
                        nightTheme={nightTheme} />
                </div>
            </div>

            <>
                {generalError &&
                    <p className={error}>
                        {t('shipping-address-form-error')}
                    </p>
                }

                {(streetAddressInvalid || address2Invalid) && !generalError &&
                    <p className={error}>
                        {t('shipping-address-form-error-streetAddressInvalid')}
                    </p>
                }

                {cityInvalid && !generalError &&
                    <p className={error}>
                        {t('shipping-address-form-error-cityInvalid')}
                    </p>
                }

                {countyInvalid && !generalError &&
                    <p className={error}>
                        {t('shipping-address-form-error-stateInvalid')}
                    </p>
                }

                {postCodeInvalid && !generalError &&
                    <p className={error}>
                        {t('shipping-address-form-error-zipCodeInvalid')}
                    </p>
                }
            </>
        </div>
    );
};
