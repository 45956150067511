import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { CUSTOMER_ID } from 'constants/payment';

export type PageType = {
    customWrapper?: boolean,
    scrollableContent?: boolean,
    header: {
        email: boolean,
        logo: boolean,
        progress: {
            percents: number | null,
            label: string | null
        } | null
    },
    route: {
        next: (data?: any) => string,
    },
    events: {
        onEnter?: (data?: any) => string,
        onLeave?: (data?: any) => string,
    },
    // @description: string - required & string[] - any of the item required
    requiredKeys?: (string | string[])[],
    footerAtTheBottom?: boolean,
};

export type PageConfigurationType = {
    [key: string]: PageType;
};

export const DEFAULT_PAGES_CONFIGURATION: PageConfigurationType = {
    [RoutePath.MainPage]: {
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.EthnicBackground,
        },
        events: {}
    },
    [RoutePath.EthnicBackground]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 4,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onLeave: () => 'RxUKWeightEthnicBackgroundClicked'
        }
    },
    [RoutePath.BodyGender]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 8,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.LoseWeight,
        },
        events: {
            onLeave: () => 'RxUKWeightGenderClicked'
        }
    },
    [RoutePath.LoseWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 16,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.FamilyWeight,
        },
        events: {
            onLeave: () => 'RxUKWeightLoseWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.FamilyWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 24,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.PastAttempts,
        },
        events: {
            onLeave: () => 'RxUKWeightFamilyWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.PastAttempts]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 32,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.ExerciseHours,
        },
        events: {
            onLeave: () => 'RxUKWeightPastAttemptsClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.ExerciseHours]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 35,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.LoseWeightReason,
        },
        events: {
            onLeave: () => 'RxUKWeightExerciseHoursClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.LoseWeightReason]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 40,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.WeightFactors,
        },
        events: {
            onLeave: () => 'RxUKWeightLoseWeightReasonClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.WeightFactors]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.PrescriptionMedications,
        },
        events: {
            onLeave: () => 'RxUKWeightWeightFactorsClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.PrescriptionMedications]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 48,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyHeight,
        },
        events: {
            onLeave: () => 'RxUKWeightPrescriptionMedicationsClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.BodyHeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 52,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyWeight,
        },
        events: {
            onLeave: () => 'RxUKWeightBodyHeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.BodyWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 56,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyIdealWeight,
        },
        events: {
            onLeave: () => 'RxUKWeightBodyWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
        ]
    },
    [RoutePath.BodyIdealWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 60,
                label: '',
            },
        },
        route: {
            next: (data) => data.eligibleBMIValue ? RoutePath.HealthCondition : RoutePath.DeclineUser,
        },
        events: {
            onLeave: () => 'RxUKWeightBodyIdealWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg
        ]
    },
    [RoutePath.HealthCondition]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 64,
                label: '',
            },
        },
        route: {
            next: (data) => data?.includes('pregnant') || data?.includes('breastfeeding') ? RoutePath.DeclineUser : RoutePath.HealthConditionPart2,
        },
        events: {
            onLeave: () => 'RxUKWeightHealthConditionClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.HealthConditionPart2]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 66,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.MedicalCondition,
        },
        events: {
            onLeave: () => 'RxUKWeightHealthConditionPart2Clicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.MedicalCondition]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 68,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.MedicalConditionPart2
        },
        events: {
            onLeave: () => 'RxUKWeightMedicalConditionClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.MedicalConditionPart2]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 70,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.MedicationIntake
        },
        events: {
            onLeave: () => 'RxUKWeightMedicalConditionPart2Clicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.MedicationIntake]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 72,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.MedicationIntakePart2
        },
        events: {
            onLeave: () => 'RxUKWeightMedicationIntakeClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.MedicationIntakePart2]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 74,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BloodPressureInformation
        },
        events: {
            onLeave: () => 'RxUKWeightMedicationIntakePart2Clicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.BloodPressureInformation]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 76,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.GeneralPractitionerInformation
        },
        events: {
            onLeave: () => 'RxUKWeightBloodPressureInformationClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.GeneralPractitionerInformation]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 78,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Consent
        },
        events: {
            onLeave: () => 'RxUKWeightGeneralPractitionerInformationClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.Consent]: {
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.Email
        },
        events: {
            onLeave: () => 'RxUKWeightConsentClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.Email]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 80,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Medication,
        },
        events: {
            onLeave: () => 'RxUKUKWeightEmailClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.Medication]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: null,
                label: '',
            },
        },
        footerAtTheBottom: true,
        route: {
            next: () => RoutePath.Checkout,
        },
        events: {
            onLeave: () => 'RxUKUKWeightMedicationClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Checkout]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Congrats
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Congrats]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onEnter: () => 'RxUKWeightCongratsViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            CUSTOMER_ID
        ]
    },
    [RoutePath.Welcome]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onLeave: () => 'RxUKWeightDownloadButtonClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            CUSTOMER_ID
        ]
    },
    [RoutePath.DeclineUser]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.MainPage,
        },
        events: {
            onLeave: () => 'RxUKWeightContinueWithoutMedicationsClicked'
        }
    },
    [RoutePath.ShippingAddressEmbedded]: { // Page for opening in WebView
        customWrapper: true,
        scrollableContent: false,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.ShippingAddressEmbedded,
        },
        events: {}
    }
};
