import React from 'react';

import { useStyles } from './styles';

export const PrimerLoader = (): JSX.Element => {
    const { primerLoading, spinner, path } = useStyles();

    return (
            <div className={primerLoading}>
                <svg className={spinner} viewBox="0 0 50 50">
                    <circle className={path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
            </div>
    );
};
