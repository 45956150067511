import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LinksKeys } from 'constants/links';
import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { useStyles } from './styles';

export const Consent = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const { multiChoiceWrapper, link } = useStyles();

    const pageKey = LocalStorageKeys[RoutePath.Consent];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const termsEvent = () => pageConfiguration.event('RxUKWeightTermsClicked');
    const privacyEvent = () => pageConfiguration.event('RxUKWeightPrivacyPolicyClicked');

    const termsLink =
        <a className={link}
           href={LinksKeys.TermsAndCondition}
           target='_blank'
           rel='noreferrer nofollow'
           onClick={termsEvent}>
        </a>;

    const privacyLink =
        <a className={link}
           href={LinksKeys.PrivacyPolicy}
           target='_blank'
           rel='noreferrer nofollow'
           onClick={privacyEvent}>
        </a>;

    const termsPrivacyPolicyText = <Trans i18nKey='consent-option-terms-privacy-policy' components={[termsLink, privacyLink]} /> as unknown as string;

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'sole-user',
            label: t('consent-option-list-item-sole-user')
        },
        {
            value: 'one-medication',
            label: t('consent-option-one-medication')
        },
        {
            value: 'weight-management-program',
            label: t('consent-option-weight-management-program')
        },
        {
            value: 'accurate-information',
            label: t('consent-option-accurate-information')
        },
        {
            value: 'read-information',
            label: t('consent-option-read-information')
        },
        {
            value: 'medical-record',
            label: t('consent-option-medical-record')
        },
        {
            value: 'terms-privacy-policy',
            label: termsPrivacyPolicyText,
        },
    ];

    const setStoredOptions = (options: IMultiChoiceButtonOption[]) => setPageValue(options.map(option => {
        const { label, ...storedOption } = option;
        return storedOption;
    }));

    const renderedOptions = pageValue.map((storedOption: Omit<IMultiChoiceButtonOption, 'label'>) => {
        return { label: multiChoices.find(choice => choice.value === storedOption.value)?.label , ...storedOption}
    })

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Heading title={t('consent-title')} />

            <div className={multiChoiceWrapper}>
                <MultiChoice
                    options={multiChoices}
                    selectedOptions={renderedOptions}
                    onSelect={setStoredOptions}
                />
            </div>

            <FloatedButton floated={!!renderedOptions.length} withGradient>
                <NextButton typographyText={t('consent-button-next')} onClick={handleClick}
                            disabled={renderedOptions.length !== multiChoices.length} />
            </FloatedButton>
        </>
    );
};
