import i18n from '../../../translations/i18n';

export const cmValidationQuery: any = {
    type: {
        value: 'number',
        moreThen: {
            value: 60,
            errorMessage: i18n.t('height-validation-error-message'),
        },
        lessThen: {
            value: 273,
            errorMessage: i18n.t('height-validation-error-message'),
        },
    },
};
