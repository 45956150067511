import { IMultiChoiceButtonOption } from '@ruby-labs/ui-core-kit';

export const getSelectedPageValues = (pageValue: IMultiChoiceButtonOption[]) => {
    return pageValue.map((val: any) => val.value);
};

export const getMultiChoiceButtonSelectOptions = (pageValue: string[], multiChoices: IMultiChoiceButtonOption[]) => {
    return pageValue.map((item: string) => {
        const currentOption = multiChoices.find((option) => option.value === item);

        return currentOption?.value === 'other' ? `${currentOption.label}: ${currentOption?.userAnswer?.trim()}` : currentOption?.label;
    }) as string[];
};

export const isNextButtonDisabled = (pageValue: string[], multiChoices: IMultiChoiceButtonOption[]) => {
    const isAnswerEmpty = (answer: string) => answer.trim().length === 0;
    const isEveryAnswerValid = pageValue.every((value: string) => {
        const option = multiChoices.find((opt) => opt.value === value);
        return option?.value === 'none' ? true : !isAnswerEmpty(option?.userAnswer?.toString() as string);
    });

    return !pageValue.length || !isEveryAnswerValid;
};
