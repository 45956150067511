import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { RoutePath } from './route-path.constant';

import { MainPage } from 'pages/main-page';
import { EthnicBackground } from 'pages/ethnic-background';
import { BodyGender } from 'pages/body-gender';
import { LoseWeight } from 'pages/lose-weight';
import { FamilyWeight } from 'pages/family-weight';
import { PastAttempts } from 'pages/past-attempts';
import { ExerciseHours } from 'pages/exercise-hours';
import { LoseWeightReason } from 'pages/lose-weight-reason';
import { WeightFactors } from 'pages/weight-factors';
import { PrescriptionMedications } from 'pages/prescription-medications';
import { BodyHeight } from 'pages/body-height';
import { BodyWeight } from 'pages/body-weight';
import { BodyIdealWeight } from 'pages/body-ideal-weight';
import { Email } from 'pages/email';
import { DeclineUser } from 'pages/decline-user';
import { MedicalCondition } from 'pages/medical-condition';
import { MedicalConditionPart2 } from 'pages/medical-condition-part-2';
import { HealthCondition } from 'pages/health-condition';
import { HealthConditionPart2 } from 'pages/health-condition-part-2';
import { MedicationIntake } from 'pages/medication-intake';
import { MedicationIntakePart2 } from 'pages/medication-intake-part-2';
import { BloodPressureInformation } from "pages/blood-pressure-information";
import { GeneralPractitionerInformation } from 'pages/general-practitioner-information';
import { Consent } from 'pages/consent';
import { Checkout } from 'pages/checkout';
import { Medication } from 'pages/medication';
import { ShippingAddressEmbedded } from 'pages/shipping-address-embedded';
import { Congrats } from 'pages/congrats';
import { Welcome } from 'pages/welcome';
import { NotFound } from 'pages/not-found';

export const RouterConfig = () => {
    return (
        <Routes>
            {/* List all public routes here */}
            <Route path={RoutePath.MainPage} element={<MainPage />} />
            <Route path={RoutePath.EthnicBackground} element={<EthnicBackground />} />
            <Route path={RoutePath.BodyGender} element={<BodyGender />} />
            <Route path={RoutePath.LoseWeight} element={<LoseWeight />} />
            <Route path={RoutePath.FamilyWeight} element={<FamilyWeight />} />
            <Route path={RoutePath.PastAttempts} element={<PastAttempts />} />
            <Route path={RoutePath.ExerciseHours} element={<ExerciseHours />} />
            <Route path={RoutePath.LoseWeightReason} element={<LoseWeightReason />} />
            <Route path={RoutePath.WeightFactors} element={<WeightFactors />} />
            <Route path={RoutePath.PrescriptionMedications} element={<PrescriptionMedications />} />
            <Route path={RoutePath.BodyHeight} element={<BodyHeight />} />
            <Route path={RoutePath.BodyWeight} element={<BodyWeight />} />
            <Route path={RoutePath.BodyIdealWeight} element={<BodyIdealWeight />} />
            <Route path={RoutePath.HealthCondition} element={<HealthCondition />} />
            <Route path={RoutePath.HealthConditionPart2} element={<HealthConditionPart2 />} />
            <Route path={RoutePath.MedicalCondition} element={<MedicalCondition />} />
            <Route path={RoutePath.MedicalConditionPart2} element={<MedicalConditionPart2 />} />
            <Route path={RoutePath.MedicationIntake} element={<MedicationIntake />} />
            <Route path={RoutePath.MedicationIntakePart2} element={<MedicationIntakePart2 />} />
            <Route path={RoutePath.BloodPressureInformation} element={<BloodPressureInformation />} />
            <Route path={RoutePath.GeneralPractitionerInformation} element={<GeneralPractitionerInformation />} />
            <Route path={RoutePath.Consent} element={<Consent />} />
            <Route path={RoutePath.Email} element={<Email />} />
            <Route path={RoutePath.Medication} element={<Medication />} />
            <Route path={RoutePath.Checkout} element={<Checkout />} />
            <Route path={RoutePath.Congrats} element={<Congrats />} />
            <Route path={RoutePath.Welcome} element={<Welcome />} />

            <Route path={RoutePath.DeclineUser} element={<DeclineUser />} />

            {/* // Page for opening in WebView */}
            <Route path={RoutePath.ShippingAddressEmbedded} element={<ShippingAddressEmbedded />} />

            {/* List a generic 404-Not Found route here */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
