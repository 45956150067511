import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getSelectedPageValues, isNextButtonDisabled } from 'helpers/multichoice-button-options';

export const HealthConditionPart2 = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.HealthConditionPart2];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: lactoseIntoleranceUserAnswer, setPageValue: setLactoseIntoleranceUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-lactose-intolerance-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: headTraumaUserAnswer, setPageValue: setHeadTraumaUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-head-trauma-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: heartProblemsUserAnswer, setPageValue: setHeartProblemsUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-heart-problems-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: circulationProblemsUserAnswer, setPageValue: setCirculationProblemsUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-circulation-problems-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: cholestasisUserAnswer, setPageValue: setCholestasisUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-cholestasis-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: allergiesUserAnswer, setPageValue: setAllergiesUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-allergies-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: smokeUserAnswer, setPageValue: setSmokeUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-smoke-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: alcoholUserAnswer, setPageValue: setAlcoholUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.HealthConditionPart2]}-alcohol-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'lactose-intolerance',
            label: t('health-condition-part-2-option-lactose-intolerance'),
            userAnswer: lactoseIntoleranceUserAnswer,
            setUserAnswer: setLactoseIntoleranceUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'head-trauma',
            label: t('health-condition-part-2-option-head-trauma'),
            userAnswer: headTraumaUserAnswer,
            setUserAnswer: setHeadTraumaUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'heart-problems',
            label: t('health-condition-part-2-option-heart-problems'),
            userAnswer: heartProblemsUserAnswer,
            setUserAnswer: setHeartProblemsUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'brain-blood-circulation-problems',
            label: t('health-condition-part-2-option-brain-blood-circulation-problems'),
            userAnswer: circulationProblemsUserAnswer,
            setUserAnswer: setCirculationProblemsUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'cholestasis',
            label: t('health-condition-part-2-option-cholestasis'),
            userAnswer: cholestasisUserAnswer,
            setUserAnswer: setCholestasisUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'allergies',
            label: t('health-condition-part-2-option-allergies'),
            userAnswer: allergiesUserAnswer,
            setUserAnswer: setAllergiesUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-details'),
        },
        {
            value: 'smoke',
            label: t('health-condition-part-2-option-smoke'),
            userAnswer: smokeUserAnswer,
            setUserAnswer: setSmokeUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-smoke'),
        },
        {
            value: 'alcohol',
            label: t('health-condition-part-2-option-alcohol'),
            userAnswer: alcoholUserAnswer,
            setUserAnswer: setAlcoholUserAnswer,
            placeholder: t('health-condition-part-2-textarea-placeholder-alcohol'),
        },
        {
            value: 'none',
            label: t('health-condition-part-2-option-none'),
        }
    ];

    const selectedPageValues = getSelectedPageValues(pageValue);

    const handleClick = () => {
        const selectOption = selectedPageValues.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.value === item);

            return currentOption?.value === 'none' ? currentOption?.label : `${currentOption?.label}: ${currentOption?.userAnswer?.trim()}`;
        });

        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('health-condition-part-2-title'),
                value: pageValue[0].value === 'none'
                    ?
                    multiChoices
                        .filter(multiChoice => multiChoice.value !== 'none')
                        .map(multiChoice => `"${multiChoice.label}" - NO`)
                    :
                    selectOption
            },
        });

        pageConfiguration.handleOnPageComplete(!selectedPageValues.includes('none') ? {
            pageValue,
            selectOption
        } : { pageValue });
    };

    return (
        <>
            <Heading title={t('health-condition-part-2-title')} subtitle={t('health-condition-part-2-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue}
            />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick}
                            disabled={isNextButtonDisabled(selectedPageValues, multiChoices)} />
            </FloatedButton>
        </>
    );
};
