class UsersApi {

    isUserActive(email: string): Promise<{ isUserActive: boolean }> {

        if (!email) {
            return Promise.resolve({ isUserActive: false });
        }

        return fetch(`${process.env.REACT_APP_ABLE_API}/users/isUserActive?email=${encodeURIComponent(email)}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response
                    .json()
                    .then(json => Promise.reject(json));
            }).then((response) => {
                return response.data;
            }).catch((e) => {
                console.log('[isUserActive request]', e);
                return Promise.resolve({ isUserActive: false });
            });
    }
}

export const UsersApiProvider = new UsersApi();
