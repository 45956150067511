import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserEligibilityKeys } from 'constants/user-eligibility';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, getSelectedPageValues } from 'helpers/multichoice-button-options';
import { isUserStatusNeedsReview } from 'helpers/get-user-eligibility';

export const MedicationIntake = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicationIntake];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'insulin',
            label: t('medication-intake-option-insulin'),
        },
        {
            value: 'glp-1-receptor-agonists',
            label: t('medication-intake-option-glp-1-receptor-agonists'),
        },
        {
            value: 'sulfonylureas',
            label: t('medication-intake-option-sulfonylureas'),
        },
        {
            value: 'contraceptive-pill',
            label: t('medication-intake-option-contraceptive-pill'),
        },
        {
            value: 'anti-diabetic-drugs',
            label: t('medication-intake-option-anti-diabetic-drugs'),
        },
        {
            value: 'levothyroxine',
            label: t('medication-intake-option-levothyroxine'),
        },
        {
            value: 'none',
            label: t('medication-intake-option-none'),
        },
    ];

    const handleClick = () => {
        const selectedPageValues = getSelectedPageValues(pageValue);
        const selectOption = getMultiChoiceButtonSelectOptions(selectedPageValues, multiChoices);

        let userStatus = selectedPageValues[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicationIntakePart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntakePart2]) as string);
        const medicalConditionPageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalCondition]) as string);
        const medicalConditionPart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalConditionPart2]) as string);

        if (
            isUserStatusNeedsReview(medicationIntakePart2PageValue, userStatus) ||
            isUserStatusNeedsReview(medicalConditionPageValue, userStatus) ||
            isUserStatusNeedsReview(medicalConditionPart2PageValue, userStatus)
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: LocalStorageKeys[RoutePath.MedicationIntake],
            itemQuestion: { question: t('medication-intake-title'), value: selectOption },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus }
        });

        pageConfiguration.handleOnPageComplete({ pageValue });
    };

    return (
        <>
            <Heading title={t('medication-intake-title')} subtitle={t('medication-intake-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                disabledWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
