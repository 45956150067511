import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidatedInput } from 'components/validated-input';

import { cmValidationQuery } from './cm-validation-query';

type CmInputProps = {
    value: string;
    autoFocus?: boolean;
    onChange: (e: any) => void;
    onError: (error: boolean, key: string) => void;
};

export const CmInput: FC<CmInputProps> = ({ value, autoFocus, onChange, onError }) => {
    const { t } = useTranslation();

    const props = useMemo(() => ({ label: t('height'), maxLength: 3 }), []);

    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(() => () => onError(false, 'cm'), []);

    return (
        <ValidatedInput
            type="number"
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            onError={handleError('cm')}
            validationQuery={cmValidationQuery}
            endAdornment={'cm'}
            {...props}
        />
    );
};
