import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { PLAN } from 'constants/plan';

import { useStyles } from './styles';

interface CartProps {
    isDesktop: boolean;
}

export const Cart: FC<CartProps> = ({ isDesktop }) => {
    const {
        cartWrapper,
        headerInfoWrapper,
        cartTitle,
        divider,
        borderTop,
        infoBlockWithImage,
        infoBlock,
        title,
        subtitle,
        blockTitle,
        boldTitle,
        price,
        fullPrice,
        period,
        trial,
        total,
    } = useStyles();

    const pageImages = useBackgroundImageService([
        'svg/checkout/cart/cart-icon.svg',
        'webp/checkout/cart/medications.webp'
    ]);

    const subscriptionTotalPrice = `£${PLAN.subscriptionAmount / 100}`;

    return (
        <div className={cartWrapper}>
            {isDesktop && <div className={headerInfoWrapper}>
                <img src={pageImages[0]} alt='Cart' />
                <h2 className={cartTitle}>
                    <Trans i18nKey='checkout-cart-summary' />
                </h2>
            </div>}

            <div>
                <div className={`${divider} ${isDesktop ? borderTop : ''}`}>
                    <div className={infoBlockWithImage}>
                        <img src={pageImages[1]} alt='Medications' />
                        <div className={infoBlock}>
                            <h3 className={title}>
                                {PLAN.title}
                            </h3>
                            <h3 className={subtitle}>
                                <Trans i18nKey='checkout-cart-details-monthly-membership' />
                            </h3>
                            <h3 className={subtitle}>
                                <Trans i18nKey='checkout-cart-details-medication-cost' />
                            </h3>
                        </div>
                    </div>
                    <p className={`${price} ${fullPrice}`}>
                        {subscriptionTotalPrice}<span className={period}>/mo</span>
                    </p>
                </div>
                <div className={divider}>
                    <h3 className={`${blockTitle} ${boldTitle}`}>
                        <Trans i18nKey='checkout-cart-details-total' />
                    </h3>
                    <h3 className={`${price} ${total}`}>
                        {subscriptionTotalPrice} <span className={trial}></span>
                    </h3>
                </div>
            </div>
        </div>
    );
};
