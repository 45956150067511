import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    sectionsList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '24px'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',

        '& img': {
            margin: '0 6px'
        },

        '&:last-of-type img': {
            display: 'none'
        }
    },
    sectionName: {
        fontSize: '14px',
        lineHeight: '18px',
        color: '#808080',
    },
    active: {
        color: '#000000'
    },
    '@media (min-width: 768px)' : {
        sectionsList: {
            marginBottom: '40px'
        }
    }
});
